/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useReducer } from "react"
import { toast } from "react-toastify"

import { useHistory } from "react-router-dom"

import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditIcon from "@mui/icons-material/Edit"
import DescriptionIcon from "@mui/icons-material/Description"
import TimerOffIcon from "@mui/icons-material/TimerOff"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline"

import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import Title from "../../components/Title"

import api from "../../services/api"
import { i18n } from "../../translate/i18n"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import CampaignModal from "../../components/CampaignModal"
import ConfirmationModal from "../../components/ConfirmationModal"
import toastError from "../../errors/toastError"
import { Grid2 } from "@mui/material"
import { isArray } from "lodash"
import { useDate } from "../../hooks/useDate"
import { socketConnection } from "../../services/socket"

const reducer = (state, action) => {
    if (action.type === "LOAD_CAMPAIGNS") {
        const campaigns = action.payload
        const newCampaigns = []

        if (isArray(campaigns)) {
            campaigns.forEach((campaign) => {
                const campaignIndex = state.findIndex(
                    (u) => u.id === campaign.id,
                )
                if (campaignIndex !== -1) {
                    state[campaignIndex] = campaign
                } else {
                    newCampaigns.push(campaign)
                }
            })
        }

        return [...state, ...newCampaigns]
    }

    if (action.type === "UPDATE_CAMPAIGNS") {
        const campaign = action.payload
        const campaignIndex = state.findIndex((u) => u.id === campaign.id)

        if (campaignIndex !== -1) {
            state[campaignIndex] = campaign
            return [...state]
        } else {
            return [campaign, ...state]
        }
    }

    if (action.type === "DELETE_CAMPAIGN") {
        const campaignId = action.payload

        const campaignIndex = state.findIndex((u) => u.id === campaignId)
        if (campaignIndex !== -1) {
            state.splice(campaignIndex, 1)
        }
        return [...state]
    }

    if (action.type === "RESET") {
        return []
    }
}

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}))

const Campaigns = () => {
    const classes = useStyles()

    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const [deletingCampaign, setDeletingCampaign] = useState(null)
    const [campaignModalOpen, setCampaignModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [searchParam, setSearchParam] = useState("")
    const [campaigns, dispatch] = useReducer(reducer, [])

    const { datetimeToClient } = useDate()

    useEffect(() => {
        dispatch({ type: "RESET" })
        setPageNumber(1)
    }, [searchParam])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            fetchCampaigns()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, pageNumber])

    useEffect(() => {
        const companyId = localStorage.getItem("companyId")
        const socket = socketConnection({ companyId })

        socket.on(`company-${companyId}-campaign`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.record })
            }
            if (data.action === "delete") {
                dispatch({ type: "DELETE_CAMPAIGN", payload: +data.id })
            }
        })
        return () => {
            socket.disconnect()
        }
    }, [])

    const fetchCampaigns = async () => {
        try {
            const { data } = await api.get("/campaigns/", {
                params: { searchParam, pageNumber },
            })
            dispatch({ type: "LOAD_CAMPAIGNS", payload: data.records })
            setHasMore(data.hasMore)
            setLoading(false)
        } catch (err) {
            toastError(err)
        }
    }

    const handleOpenCampaignModal = () => {
        setSelectedCampaign(null)
        setCampaignModalOpen(true)
    }

    const handleCloseCampaignModal = () => {
        setSelectedCampaign(null)
        setCampaignModalOpen(false)
    }

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase())
    }

    const handleEditCampaign = (campaign) => {
        setSelectedCampaign(campaign)
        setCampaignModalOpen(true)
    }

    const handleDeleteCampaign = async (campaignId) => {
        try {
            await api.delete(`/campaigns/${campaignId}`)
            toast.success(i18n.t("campaigns.toasts.deleted"))
        } catch (err) {
            toastError(err)
        }
        setDeletingCampaign(null)
        setSearchParam("")
        setPageNumber(1)
    }

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1)
    }

    const handleScroll = (e) => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    const formatStatus = (val) => {
        switch (val) {
            case "INATIVA":
                return "Inativa"
            case "PROGRAMADA":
                return "Programada"
            case "EM_ANDAMENTO":
                return "Em Andamento"
            case "CANCELADA":
                return "Cancelada"
            case "FINALIZADA":
                return "Finalizada"
            default:
                return val
        }
    }

    const cancelCampaign = async (campaign) => {
        try {
            await api.post(`/campaigns/${campaign.id}/cancel`)
            toast.success(i18n.t("campaigns.toasts.cancel"))
            setPageNumber(1)
            fetchCampaigns()
        } catch (err) {
            toast.error(err.message)
        }
    }

    const restartCampaign = async (campaign) => {
        try {
            await api.post(`/campaigns/${campaign.id}/restart`)
            toast.success(i18n.t("campaigns.toasts.restart"))
            setPageNumber(1)
            fetchCampaigns()
        } catch (err) {
            toast.error(err.message)
        }
    }

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingCampaign &&
                    `${i18n.t("campaigns.confirmationModal.deleteTitle")} ${
                        deletingCampaign.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteCampaign(deletingCampaign.id)}
            >
                {i18n.t("campaigns.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <CampaignModal
                resetPagination={() => {
                    setPageNumber(1)
                    fetchCampaigns()
                }}
                open={campaignModalOpen}
                onClose={handleCloseCampaignModal}
                aria-labelledby="form-dialog-title"
                campaignId={selectedCampaign && selectedCampaign.id}
            />
            <MainHeader>
                <Grid2 style={{ width: "99.6%" }} container>
                    <Grid2 size={{ xs: 12, sm: 8 }} item>
                        <Title>{i18n.t("campaigns.title")}</Title>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 4 }} item>
                        <Grid2 spacing={2} container>
                            <Grid2 size={{ xs: 6, sm: 6 }} item>
                                <TextField
                                    fullWidth
                                    placeholder={i18n.t(
                                        "campaigns.searchPlaceholder",
                                    )}
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    size="small"
                                    slotProps={{
                                        input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon
                                                        style={{
                                                            color: "gray",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, sm: 6 }} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleOpenCampaignModal}
                                    color="primary"
                                >
                                    {i18n.t("campaigns.buttons.add")}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("campaigns.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("campaigns.table.status")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("campaigns.table.contactList")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("campaigns.table.whatsapp")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("campaigns.table.scheduledAt")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("campaigns.table.completedAt")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("campaigns.table.confirmation")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("campaigns.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {campaigns.map((campaign) => (
                                <TableRow key={campaign.id}>
                                    <TableCell align="center">
                                        {campaign.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatStatus(campaign.status)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.contactListId
                                            ? campaign.contactList.name
                                            : "Não definida"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.whatsappId
                                            ? campaign.whatsapp.name
                                            : "Não definido"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.scheduledAt
                                            ? datetimeToClient(
                                                  campaign.scheduledAt,
                                              )
                                            : "Sem agendamento"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.completedAt
                                            ? datetimeToClient(
                                                  campaign.completedAt,
                                              )
                                            : "Não concluída"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.confirmation
                                            ? "Habilitada"
                                            : "Desabilitada"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.status === "EM_ANDAMENTO" && (
                                            <IconButton
                                                onClick={() =>
                                                    cancelCampaign(campaign)
                                                }
                                                title="Parar Campanha"
                                                size="small"
                                            >
                                                <PauseCircleOutlineIcon />
                                            </IconButton>
                                        )}
                                        {campaign.status === "CANCELADA" && (
                                            <IconButton
                                                onClick={() =>
                                                    restartCampaign(campaign)
                                                }
                                                title="Parar Campanha"
                                                size="small"
                                            >
                                                <PlayCircleOutlineIcon />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            onClick={() =>
                                                history.push(
                                                    `/campaign/${campaign.id}/report`,
                                                )
                                            }
                                            size="small"
                                        >
                                            <DescriptionIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditCampaign(campaign)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true)
                                                setDeletingCampaign(campaign)
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={8} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Campaigns
