import React, { useState, useEffect, useContext, useRef } from "react"
// import { Picker } from "emoji-mart"
// import "emoji-mart/css/emoji-mart.css"
import MicRecorder from "mic-recorder-to-mp3"
import clsx from "clsx"

import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import CircularProgress from "@mui/material/CircularProgress"
import { green } from "@mui/material/colors"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import IconButton from "@mui/material/IconButton"
import MoodIcon from "@mui/icons-material/Mood"
import SendIcon from "@mui/icons-material/Send"
import CancelIcon from "@mui/icons-material/Cancel"
import ClearIcon from "@mui/icons-material/Clear"
import MicIcon from "@mui/icons-material/Mic"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { FormControlLabel, Switch } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { isString, isEmpty, isObject, has } from "lodash"
import StorageIcon from "@mui/icons-material/Storage"

import { i18n } from "../../translate/i18n"
import api from "../../services/api"
import RecordingTimer from "./RecordingTimer"
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext"
import { AuthContext } from "../../context/Auth/AuthContext"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import toastError from "../../errors/toastError"
import ModalSelectAudio from "../ModalSelectAudio"
import useQuickMessages from "../../hooks/useQuickMessages"

import Compressor from "compressorjs"
import LinearWithValueLabel from "./ProgressBarCustom"
import { useWindowSize } from "usehooks-ts"

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        background: "#eee",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    newMessageBox: {
        background: "#eee",
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "center",
    },

    messageInputWrapper: {
        padding: 6,
        marginRight: 7,
        background: theme.palette.total,
        display: "flex",
        borderRadius: 20,
        flex: 1,
    },

    messageInput: {
        paddingLeft: 10,
        flex: 1,
        border: "none",
    },

    sendMessageIcons: {
        color: "grey",
    },

    uploadInput: {
        display: "none",
    },

    viewMediaInputWrapper: {
        display: "flex",
        padding: "10px 13px",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.palette.total,
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    emojiBox: {
        position: "absolute",
        bottom: 63,
        width: 40,
        borderTop: "1px solid #e8e8e8",
    },

    circleLoading: {
        color: green[500],
        opacity: "70%",
        position: "absolute",
        top: "20%",
        left: "50%",
        marginLeft: -12,
    },

    audioLoading: {
        color: green[500],
        opacity: "70%",
    },

    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },

    replyginMsgWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 8,
        paddingLeft: 73,
        paddingRight: 7,
    },

    replyginMsgContainer: {
        flex: 1,
        marginRight: 5,
        overflowY: "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },

    replyginMsgBody: {
        padding: 10,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },

    replyginContactMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#35cd96",
    },

    replyginSelfMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#6bcbef",
    },

    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 500,
    },
}))

const EmojiOptions = (props) => {
    const { disabled, showEmoji, setShowEmoji } = props
    const classes = useStyles()
    return (
        <>
            <IconButton
                aria-label="emojiPicker"
                component="span"
                disabled={disabled}
                onClick={() => setShowEmoji((prevState) => !prevState)}
            >
                <MoodIcon className={classes.sendMessageIcons} />
            </IconButton>
            {showEmoji ? (
                <div className={classes.emojiBox}>
                    {/* <Picker
                        perLine={16}
                        showPreview={false}
                        showSkinTones={false}
                        onSelect={handleAddEmoji}
                    /> */}
                </div>
            ) : null}
        </>
    )
}

const SignSwitch = (props) => {
    const { setSignMessage, signMessage } = props
    const { width } = useWindowSize()

    if (width > 768) {
        return (
            <FormControlLabel
                style={{ marginRight: 7, color: "gray" }}
                label={i18n.t("messagesInput.signMessage")}
                labelPlacement="start"
                control={
                    <Switch
                        size="small"
                        checked={signMessage}
                        onChange={(e) => {
                            setSignMessage(e.target.checked)
                        }}
                        name="showAllTickets"
                        color="primary"
                    />
                }
            />
        )
    }
    return null
}

const FileInput = (props) => {
    const { handleChangeMedias, disableOption } = props
    const classes = useStyles()
    return (
        <>
            <input
                multiple
                type="file"
                id="upload-button"
                disabled={disableOption()}
                className={classes.uploadInput}
                onChange={handleChangeMedias}
            />
            <label htmlFor="upload-button">
                <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={disableOption()}
                >
                    <AttachFileIcon className={classes.sendMessageIcons} />
                </IconButton>
            </label>
        </>
    )
}

const ActionButtons = (props) => {
    const {
        inputMessage,
        loading,
        recording,
        ticketStatus,
        handleSendMessage,
        handleCancelAudio,
        handleUploadAudio,
        handleStartRecording,
        handleModalSendRecordedAudio,
    } = props
    const classes = useStyles()
    if (inputMessage) {
        return (
            <IconButton
                aria-label="sendMessage"
                component="span"
                onClick={handleSendMessage}
                disabled={loading}
            >
                <SendIcon className={classes.sendMessageIcons} />
            </IconButton>
        )
    } else if (recording) {
        return (
            <div className={classes.recorderWrapper}>
                <IconButton
                    aria-label="cancelRecording"
                    component="span"
                    fontSize="large"
                    disabled={loading}
                    onClick={handleCancelAudio}
                >
                    <HighlightOffIcon className={classes.cancelAudioIcon} />
                </IconButton>
                {loading ? (
                    <div>
                        <CircularProgress className={classes.audioLoading} />
                    </div>
                ) : (
                    <RecordingTimer />
                )}

                <IconButton
                    aria-label="sendRecordedAudio"
                    component="span"
                    onClick={handleUploadAudio}
                    disabled={loading}
                >
                    <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                </IconButton>
            </div>
        )
    } else {
        return (
            <>
                <IconButton
                    aria-label="showRecorder"
                    component="span"
                    disabled={loading || ticketStatus !== "open"}
                    onClick={handleStartRecording}
                >
                    <MicIcon className={classes.sendMessageIcons} />
                </IconButton>
                <IconButton
                    aria-label="showRecorder"
                    component="span"
                    disabled={loading || ticketStatus !== "open"}
                    onClick={handleModalSendRecordedAudio}
                >
                    <StorageIcon className={classes.sendMessageIcons} />
                </IconButton>
            </>
        )
    }
}

const CustomInput = (props) => {
    const {
        loading,
        inputRef,
        ticketStatus,
        inputMessage,
        setInputMessage,
        handleSendMessage,
        handleInputPaste,
        disableOption,
    } = props
    const classes = useStyles()
    const [quickMessages, setQuickMessages] = useState([])
    const [options, setOptions] = useState([])
    const [popupOpen, setPopupOpen] = useState(false)

    const { user } = useContext(AuthContext)

    const { list: listQuickMessages } = useQuickMessages()

    useEffect(() => {
        async function fetchData() {
            const companyId = localStorage.getItem("companyId")
            const messages = await listQuickMessages({
                companyId,
                userId: user.id,
            })
            const options = messages.map((m) => {
                let truncatedMessage = m.message
                if (
                    isString(truncatedMessage) &&
                    truncatedMessage.length > 35
                ) {
                    truncatedMessage = m.message.substring(0, 35) + "..."
                }
                return {
                    value: m.message,
                    label: `/${m.shortcode} - ${truncatedMessage}`,
                }
            })
            setQuickMessages(options)
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (
            isString(inputMessage) &&
            !isEmpty(inputMessage) &&
            inputMessage.length > 1
        ) {
            const firstWord = inputMessage.charAt(0)
            setPopupOpen(firstWord.indexOf("/") > -1)

            const filteredOptions = quickMessages.filter(
                (m) => m.label.indexOf(inputMessage) > -1,
            )
            setOptions(filteredOptions)
        } else {
            setPopupOpen(false)
        }
    }, [inputMessage])

    const onKeyPress = (e) => {
        if (loading || e.shiftKey) return
        else if (e.key === "Enter") {
            handleSendMessage()
        }
    }

    const onPaste = (e) => {
        if (ticketStatus === "open") {
            handleInputPaste(e)
        }
    }

    const renderPlaceholder = () => {
        if (ticketStatus === "open") {
            return i18n.t("messagesInput.placeholderOpen")
        }
        return i18n.t("messagesInput.placeholderClosed")
    }

    const setInputRef = (input) => {
        if (input) {
            input.focus()
            inputRef.current = input
        }
    }

    return (
        <div className={classes.messageInputWrapper}>
            <Autocomplete
                freeSolo
                open={popupOpen}
                id="grouped-demo"
                value={inputMessage}
                options={options}
                closeIcon={null}
                getOptionLabel={(option) => {
                    if (isObject(option)) {
                        return option.label
                    } else {
                        return option
                    }
                }}
                onChange={(event, opt) => {
                    if (isObject(opt) && has(opt, "value")) {
                        setInputMessage(opt.value)
                        setTimeout(() => {
                            inputRef.current.scrollTop =
                                inputRef.current.scrollHeight
                        }, 200)
                    }
                }}
                onInputChange={(event, opt, reason) => {
                    if (reason === "input") {
                        setInputMessage(event.target.value)
                    }
                }}
                onPaste={onPaste}
                onKeyPress={onKeyPress}
                style={{ width: "100%" }}
                renderInput={(params) => {
                    const { ...rest } = params
                    return (
                        <InputBase
                            {...params.InputProps}
                            {...rest}
                            disabled={disableOption()}
                            inputRef={setInputRef}
                            placeholder={renderPlaceholder()}
                            multiline
                            className={classes.messageInput}
                            maxRows={5}
                        />
                    )
                }}
            />
        </div>
    )
}

const MessageInputCustom = (props) => {
    const { ticketStatus, ticketId, contact } = props
    const classes = useStyles()

    const [medias, setMedias] = useState([])
    const [inputMessage, setInputMessage] = useState("")
    const [showEmoji, setShowEmoji] = useState(false)
    const [loading, setLoading] = useState(false)
    const [recording, setRecording] = useState(false)
    const [percentLoading, setPercentLoading] = useState(0)
    const [modalSendRecordedAudio, setModalSendRecordedAudio] = useState(false)

    const inputRef = useRef()
    const { setReplyingMessage, replyingMessage } =
        useContext(ReplyMessageContext)
    const { user } = useContext(AuthContext)

    const [signMessage, setSignMessage] = useLocalStorage("signOption", true)

    useEffect(() => {
        inputRef.current.focus()
    }, [replyingMessage])

    useEffect(() => {
        inputRef.current.focus()
        return () => {
            setInputMessage("")
            setShowEmoji(false)
            setMedias([])
            setReplyingMessage(null)
        }
    }, [ticketId, setReplyingMessage])

    // const handleChangeInput = e => {
    // 	if (isObject(e) && has(e, 'value')) {
    // 		setInputMessage(e.value);
    // 	} else {
    // 		setInputMessage(e.target.value)
    // 	}
    // };

    const handleAddEmoji = (e) => {
        let emoji = e.native
        setInputMessage((prevState) => prevState + emoji)
    }

    const handleChangeMedias = (e) => {
        if (!e.target.files) {
            return
        }

        const selectedMedias = Array.from(e.target.files)
        setMedias(selectedMedias)
    }

    const handleInputPaste = (e) => {
        if (e.clipboardData.files[0]) {
            setMedias([e.clipboardData.files[0]])
        }
    }

    const handleUploadMedia = async (e) => {
        setLoading(true)
        e.preventDefault()

        const formData = new FormData()
        formData.append("fromMe", true)

        medias.forEach(async (media) => {
            const file = media

            if (!file) {
                return
            }

            if (media?.type.split("/")[0] == "image") {
                new Compressor(file, {
                    quality: 0.7,

                    async success(media) {
                        //const formData = new FormData();
                        // The third parameter is required for server
                        //formData.append('file', result, result.name);

                        formData.append("medias", media)
                        formData.append("body", media.name)
                    },
                    error(err) {
                        alert("erro")
                        console.log(err.message)
                    },
                })
            } else {
                formData.append("medias", media)
                formData.append("body", media.name)
            }
        })

        setTimeout(async () => {
            try {
                await api
                    .post(`/messages/${ticketId}`, formData, {
                        onUploadProgress: (event) => {
                            let progress = Math.round(
                                (event.loaded * 100) / event.total,
                            )
                            setPercentLoading(progress)
                            console.log(
                                `A imagem  está ${progress}% carregada... `,
                            )
                        },
                    })
                    .then(() => {
                        setLoading(false)
                        setMedias([])
                        setPercentLoading(0)
                        console.log(`A imagem á foi enviada para o servidor!`)
                    })
                    .catch((err) => {
                        console.error(
                            `Houve um problema ao realizar o upload da imagem.`,
                        )
                        console.log(err)
                    })
            } catch (err) {
                toastError(err)
            }
        }, 2000)
    }

    const handleSendMessage = async () => {
        if (inputMessage.trim() === "") return
        setLoading(true)

        const message = {
            read: 1,
            fromMe: true,
            mediaUrl: "",
            body: signMessage
                ? `*${user?.name}:*\n${inputMessage.trim()}`
                : inputMessage.trim(),
            quotedMsg: replyingMessage,
        }
        try {
            await api.post(`/messages/${ticketId}`, message)
        } catch (err) {
            toastError(err)
        }

        setInputMessage("")
        setShowEmoji(false)
        setLoading(false)
        setReplyingMessage(null)
    }

    const handleStartRecording = async () => {
        setLoading(true)
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true })
            await Mp3Recorder.start()
            setRecording(true)
            setLoading(false)
        } catch (err) {
            toastError(err)
            setLoading(false)
        }
    }

    const handleUploadAudio = async () => {
        setLoading(true)
        try {
            const [, blob] = await Mp3Recorder.stop().getMp3()
            if (blob.size < 10000) {
                setLoading(false)
                setRecording(false)
                return
            }

            const formData = new FormData()
            const filename = `audio-record-site-${new Date().getTime()}.mp3`
            formData.append("medias", blob, filename)
            formData.append("body", filename)
            formData.append("fromMe", true)

            await api.post(`/messages/${ticketId}`, formData)
        } catch (err) {
            toastError(err)
        }

        setRecording(false)
        setLoading(false)
    }

    const handleCancelAudio = async () => {
        try {
            await Mp3Recorder.stop().getMp3()
            setRecording(false)
        } catch (err) {
            toastError(err)
        }
    }

    const disableOption = () => {
        return loading || recording || ticketStatus !== "open"
    }

    const handleModalSendRecordedAudio = () => {
        setModalSendRecordedAudio(true)
    }

    const renderReplyingMessage = (message) => {
        return (
            <div className={classes.replyginMsgWrapper}>
                <div className={classes.replyginMsgContainer}>
                    <span
                        className={clsx(classes.replyginContactMsgSideColor, {
                            [classes.replyginSelfMsgSideColor]: !message.fromMe,
                        })}
                    ></span>
                    <div className={classes.replyginMsgBody}>
                        {!message.fromMe && (
                            <span className={classes.messageContactName}>
                                {message.contact?.name}
                            </span>
                        )}
                        {message.body}
                    </div>
                </div>
                <IconButton
                    aria-label="showRecorder"
                    component="span"
                    disabled={loading || ticketStatus !== "open"}
                    onClick={() => setReplyingMessage(null)}
                >
                    <ClearIcon className={classes.sendMessageIcons} />
                </IconButton>
            </div>
        )
    }

    if (medias.length > 0)
        return (
            <Paper
                elevation={0}
                square
                className={classes.viewMediaInputWrapper}
            >
                <IconButton
                    aria-label="cancel-upload"
                    component="span"
                    onClick={() => setMedias([])}
                >
                    <CancelIcon className={classes.sendMessageIcons} />
                </IconButton>

                {loading ? (
                    <div>
                        {/*<CircularProgress className={classes.circleLoading} />*/}
                        <LinearWithValueLabel progress={percentLoading} />
                    </div>
                ) : (
                    <span>
                        {medias[0]?.name}
                        {/* <img src={media.preview} alt=""></img> */}
                    </span>
                )}
                <IconButton
                    aria-label="send-upload"
                    component="span"
                    onClick={handleUploadMedia}
                    disabled={loading}
                >
                    <SendIcon className={classes.sendMessageIcons} />
                </IconButton>
            </Paper>
        )
    else {
        return (
            <Paper square elevation={0} className={classes.mainWrapper}>
                {replyingMessage && renderReplyingMessage(replyingMessage)}

                <ModalSelectAudio
                    open={modalSendRecordedAudio}
                    handleClose={() => {
                        setModalSendRecordedAudio(false)
                    }}
                    contactId={contact?.id}
                    ticketId={ticketId}
                />
                <div className={classes.newMessageBox}>
                    <EmojiOptions
                        disabled={disableOption()}
                        handleAddEmoji={handleAddEmoji}
                        showEmoji={showEmoji}
                        setShowEmoji={setShowEmoji}
                    />

                    <FileInput
                        disableOption={disableOption}
                        handleChangeMedias={handleChangeMedias}
                    />

                    <SignSwitch
                        width={props.width}
                        setSignMessage={setSignMessage}
                        signMessage={signMessage}
                    />

                    <CustomInput
                        loading={loading}
                        inputRef={inputRef}
                        ticketStatus={ticketStatus}
                        inputMessage={inputMessage}
                        setInputMessage={setInputMessage}
                        // handleChangeInput={handleChangeInput}
                        handleSendMessage={handleSendMessage}
                        handleInputPaste={handleInputPaste}
                        disableOption={disableOption}
                    />

                    <ActionButtons
                        inputMessage={inputMessage}
                        loading={loading}
                        recording={recording}
                        ticketStatus={ticketStatus}
                        handleSendMessage={handleSendMessage}
                        handleCancelAudio={handleCancelAudio}
                        handleUploadAudio={handleUploadAudio}
                        handleStartRecording={handleStartRecording}
                        handleModalSendRecordedAudio={
                            handleModalSendRecordedAudio
                        }
                    />
                </div>
            </Paper>
        )
    }
}

export default MessageInputCustom
