import React, { useState, useEffect, useReducer } from "react"
import { toast } from "react-toastify"

import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditIcon from "@mui/icons-material/Edit"

import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import Title from "../../components/Title"

import api from "../../services/api"
import { i18n } from "../../translate/i18n"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import AnnouncementModal from "../../components/AnnouncementModal"
import ConfirmationModal from "../../components/ConfirmationModal"
import toastError from "../../errors/toastError"
import { Grid2 } from "@mui/material"
import { isArray } from "lodash"
import { socketConnection } from "../../services/socket"

const reducer = (state, action) => {
    if (action.type === "LOAD_ANNOUNCEMENTS") {
        const announcements = action.payload
        const newAnnouncements = []

        if (isArray(announcements)) {
            announcements.forEach((announcement) => {
                const announcementIndex = state.findIndex(
                    (u) => u.id === announcement.id,
                )
                if (announcementIndex !== -1) {
                    state[announcementIndex] = announcement
                } else {
                    newAnnouncements.push(announcement)
                }
            })
        }

        return [...state, ...newAnnouncements]
    }

    if (action.type === "UPDATE_ANNOUNCEMENTS") {
        const announcement = action.payload
        const announcementIndex = state.findIndex(
            (u) => u.id === announcement.id,
        )

        if (announcementIndex !== -1) {
            state[announcementIndex] = announcement
            return [...state]
        } else {
            return [announcement, ...state]
        }
    }

    if (action.type === "DELETE_ANNOUNCEMENT") {
        const announcementId = action.payload

        const announcementIndex = state.findIndex(
            (u) => u.id === announcementId,
        )
        if (announcementIndex !== -1) {
            state.splice(announcementIndex, 1)
        }
        return [...state]
    }

    if (action.type === "RESET") {
        return []
    }
}

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}))

const Announcements = () => {
    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null)
    const [deletingAnnouncement, setDeletingAnnouncement] = useState(null)
    const [announcementModalOpen, setAnnouncementModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [searchParam, setSearchParam] = useState("")
    const [announcements, dispatch] = useReducer(reducer, [])

    useEffect(() => {
        dispatch({ type: "RESET" })
        setPageNumber(1)
    }, [searchParam])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            fetchAnnouncements()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, pageNumber])

    useEffect(() => {
        const companyId = localStorage.getItem("companyId")
        const socket = socketConnection({ companyId })

        socket.on(`company-announcement`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_ANNOUNCEMENTS", payload: data.record })
            }
            if (data.action === "delete") {
                dispatch({ type: "DELETE_ANNOUNCEMENT", payload: +data.id })
            }
        })
        return () => {
            socket.disconnect()
        }
    }, [])

    const fetchAnnouncements = async () => {
        try {
            const { data } = await api.get("/announcements/", {
                params: { searchParam, pageNumber },
            })

            dispatch({ type: "LOAD_ANNOUNCEMENTS", payload: data.records })
            setHasMore(data.hasMore)
            setLoading(false)
        } catch (err) {
            toastError(err)
        }
    }

    const handleOpenAnnouncementModal = () => {
        setSelectedAnnouncement(null)
        setAnnouncementModalOpen(true)
    }

    const handleCloseAnnouncementModal = () => {
        setSelectedAnnouncement(null)
        setAnnouncementModalOpen(false)
    }

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase())
    }

    const handleEditAnnouncement = (announcement) => {
        setSelectedAnnouncement(announcement)
        setAnnouncementModalOpen(true)
    }

    const handleDeleteAnnouncement = async (data) => {
        try {
            if (data.mediaPath) {
                await api.delete(`/announcements/${data.id}/media-upload`)
            }

            await api.delete(`/announcements/${data.id}`)
            toast.success(i18n.t("announcements.toasts.deleted"))
        } catch (err) {
            toastError(err)
        }
        setDeletingAnnouncement(null)
        setSearchParam("")
        setPageNumber(1)
        fetchAnnouncements()
    }

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1)
    }

    const handleScroll = (e) => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    const translatePriority = (val) => {
        if (val === 1) {
            return "Alta"
        }
        if (val === 2) {
            return "Média"
        }
        if (val === 3) {
            return "Baixa"
        }
    }

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingAnnouncement &&
                    `${i18n.t("announcements.confirmationModal.deleteTitle")} ${
                        deletingAnnouncement.title
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteAnnouncement(deletingAnnouncement)}
            >
                {i18n.t("announcements.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <AnnouncementModal
                resetPagination={() => {
                    setPageNumber(1)
                    fetchAnnouncements()
                }}
                open={announcementModalOpen}
                onClose={handleCloseAnnouncementModal}
                aria-labelledby="form-dialog-title"
                announcementId={selectedAnnouncement && selectedAnnouncement.id}
            />
            <MainHeader>
                <Grid2
                    style={{ width: "99.6%" }}
                    container
                    justifyContent="space-between"
                >
                    <Grid2 size={{ xs: 12, sm: 8 }} item>
                        <Title>{i18n.t("announcements.title")}</Title>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 4 }} item>
                        <Grid2 spacing={2} container>
                            <Grid2 size={{ xs: 6, sm: 6 }} item>
                                <TextField
                                    fullWidth
                                    placeholder={i18n.t(
                                        "announcements.searchPlaceholder",
                                    )}
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    size="small"
                                    slotProps={{
                                        input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon
                                                        style={{
                                                            color: "gray",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, sm: 6 }} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleOpenAnnouncementModal}
                                    color="primary"
                                >
                                    {i18n.t("announcements.buttons.add")}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("announcements.table.title")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("announcements.table.priority")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("announcements.table.mediaName")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("announcements.table.status")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("announcements.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {announcements.map((announcement) => (
                                <TableRow key={announcement.id}>
                                    <TableCell align="center">
                                        {announcement.title}
                                    </TableCell>
                                    <TableCell align="center">
                                        {translatePriority(
                                            announcement.priority,
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {announcement.mediaName ?? "Sem anexo"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {announcement.status
                                            ? "ativo"
                                            : "inativo"}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditAnnouncement(
                                                    announcement,
                                                )
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setConfirmModalOpen(true)
                                                setDeletingAnnouncement(
                                                    announcement,
                                                )
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Announcements
