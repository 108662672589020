import React from "react"

import Tickets from "../TicketsCustom"
import TicketAdvanced from "../TicketsAdvanced"

import { useWindowSize } from "usehooks-ts"

function TicketResponsiveContainer() {
    const { width } = useWindowSize()

    if (width > 768) {
        return <Tickets />
    }
    return <TicketAdvanced />
}

export default TicketResponsiveContainer
