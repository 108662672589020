import styled from "styled-components"

export const ColumnStyles = styled("div")`
    & .react-kanban-column {
        background-color: ${(props) => props.backgroundColor};
        border-radius: 12px !important;

        & > form > input[type="text"] {
            font: inherit;
            letter-spacing: inherit;
            color: currentColor;
            padding: 4px 0 5px;
            border: 0;
            box-sizing: content-box;
            background: ${(props) => props.backgroundColor};
            border: solid 1px #cccccc;
            border-radius: 10px 0 0 10px;
            height: 1.4375em;
            margin: 0;
            -webkit-tap-highlight-color: transparent;
            display: block;
            min-width: 0;
            width: 100%;
            -webkit-animation-name: mui-auto-fill-cancel;
            animation-name: mui-auto-fill-cancel;
            -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
            padding-top: 1px;
            padding: 16.5px 14px;
            padding: 8.5px 14px;
        }

        & form > button {
            outline: none;
            border: solid 1px #cccccc;
            cursor: pointer;
            color: #333333;
            &:last-of-type {
                border-radius: 0 10px 10px 0;
            }
        }
    }

    & .react-kanban-column-adder-button {
        border-radius: 12px;
        font-size: 22px;
        color: #cccccc;
    }
`
