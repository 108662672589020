import React, {
    useState,
    useEffect,
    useReducer,
    useContext,
    useRef,
} from "react"

import { toast } from "react-toastify"
import { useParams, useHistory } from "react-router-dom"

import { makeStyles } from "@mui/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import SearchIcon from "@mui/icons-material/Search"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

import IconButton from "@mui/material/IconButton"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditIcon from "@mui/icons-material/Edit"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import BlockIcon from "@mui/icons-material/Block"

import api from "../../services/api"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import ContactListItemModal from "../../components/ContactListItemModal"
import ConfirmationModal from "../../components/ConfirmationModal/"

import { i18n } from "../../translate/i18n"
import MainHeader from "../../components/MainHeader"
import Title from "../../components/Title"
import MainContainer from "../../components/MainContainer"
import toastError from "../../errors/toastError"
import { AuthContext } from "../../context/Auth/AuthContext"
import { Can } from "../../components/Can"
import useContactLists from "../../hooks/useContactLists"
import { Grid2 } from "@mui/material"

import planilhaExemplo from "../../assets/planilha.xlsx"
import { socketConnection } from "../../services/socket"

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload
        const newContacts = []

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id)
            if (contactIndex !== -1) {
                state[contactIndex] = contact
            } else {
                newContacts.push(contact)
            }
        })

        return [...state, ...newContacts]
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload
        const contactIndex = state.findIndex((c) => c.id === contact.id)

        if (contactIndex !== -1) {
            state[contactIndex] = contact
            return [...state]
        } else {
            return [contact, ...state]
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload

        const contactIndex = state.findIndex((c) => c.id === contactId)
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1)
        }
        return [...state]
    }

    if (action.type === "RESET") {
        return []
    }
}

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}))

const ContactListItems = () => {
    const classes = useStyles()

    const { user } = useContext(AuthContext)
    const { contactListId } = useParams()
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [searchParam, setSearchParam] = useState("")
    const [contacts, dispatch] = useReducer(reducer, [])
    const [selectedContactId, setSelectedContactId] = useState(null)
    const [contactListItemModalOpen, setContactListItemModalOpen] =
        useState(false)
    const [deletingContact, setDeletingContact] = useState(null)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [contactList, setContactList] = useState({})
    const fileUploadRef = useRef(null)

    const { findById: findContactList } = useContactLists()

    useEffect(() => {
        findContactList(contactListId).then((data) => {
            setContactList(data)
        })
    }, [contactListId])

    useEffect(() => {
        dispatch({ type: "RESET" })
        setPageNumber(1)
    }, [searchParam])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get(`contact-list-items`, {
                        params: { searchParam, pageNumber, contactListId },
                    })
                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts })
                    setHasMore(data.hasMore)
                    setLoading(false)
                } catch (err) {
                    toastError(err)
                }
            }
            fetchContacts()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, pageNumber, contactListId])

    useEffect(() => {
        const companyId = localStorage.getItem("companyId")
        const socket = socketConnection({ companyId })

        socket.on(`company-${companyId}-ContactListItem`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_CONTACTS", payload: data.record })
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_CONTACT", payload: +data.id })
            }

            if (data.action === "reload") {
                dispatch({ type: "LOAD_CONTACTS", payload: data.records })
            }
        })

        socket.on(
            `company-${companyId}-ContactListItem-${contactListId}`,
            (data) => {
                if (data.action === "reload") {
                    dispatch({ type: "LOAD_CONTACTS", payload: data.records })
                }
            },
        )

        return () => {
            socket.disconnect()
        }
    }, [contactListId])

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase())
    }

    const handleOpenContactListItemModal = () => {
        setSelectedContactId(null)
        setContactListItemModalOpen(true)
    }

    const handleCloseContactListItemModal = () => {
        setSelectedContactId(null)
        setContactListItemModalOpen(false)
    }

    const hadleEditContact = (contactId) => {
        setSelectedContactId(contactId)
        setContactListItemModalOpen(true)
    }

    const handleDeleteContact = async (contactId) => {
        try {
            await api.delete(`/contact-list-items/${contactId}`)
            toast.success(i18n.t("contacts.toasts.deleted"))
        } catch (err) {
            toastError(err)
        }
        setDeletingContact(null)
        setSearchParam("")
        setPageNumber(1)
    }

    const handleImportContacts = async () => {
        try {
            const formData = new FormData()
            formData.append("file", fileUploadRef.current.files[0])
            await api.request({
                url: `contact-lists/${contactListId}/upload`,
                method: "POST",
                data: formData,
            })
        } catch (err) {
            toastError(err)
        }
    }

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1)
    }

    const handleScroll = (e) => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    const goToContactLists = () => {
        history.push("/contact-lists")
    }

    return (
        <MainContainer className={classes.mainContainer}>
            <ContactListItemModal
                open={contactListItemModalOpen}
                onClose={handleCloseContactListItemModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            ></ContactListItemModal>
            <ConfirmationModal
                title={
                    deletingContact
                        ? `${i18n.t(
                              "contactListItems.confirmationModal.deleteTitle",
                          )} ${deletingContact.name}?`
                        : `${i18n.t(
                              "contactListItems.confirmationModal.importTitlte",
                          )}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={() =>
                    deletingContact
                        ? handleDeleteContact(deletingContact.id)
                        : handleImportContacts()
                }
            >
                {deletingContact ? (
                    `${i18n.t(
                        "contactListItems.confirmationModal.deleteMessage",
                    )}`
                ) : (
                    <>
                        {i18n.t(
                            "contactListItems.confirmationModal.importMessage",
                        )}
                        <a href={planilhaExemplo} download="planilha.xlsx">
                            Clique aqui para baixar planilha exemplo.
                        </a>
                    </>
                )}
            </ConfirmationModal>
            <MainHeader>
                <Grid2 style={{ width: "99.6%" }} container>
                    <Grid2 size={{ xs: 12, sm: 5 }} item>
                        <Title>{contactList.name}</Title>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 7 }} item>
                        <Grid2 spacing={2} container>
                            <Grid2 size={{ xs: 12, sm: 6 }} item>
                                <TextField
                                    fullWidth
                                    placeholder={i18n.t(
                                        "contactListItems.searchPlaceholder",
                                    )}
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    size="small"
                                    slotProps={{
                                        input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon
                                                        style={{
                                                            color: "gray",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 4, sm: 2 }} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={goToContactLists}
                                >
                                    {i18n.t("contactListItems.buttons.lists")}
                                </Button>
                            </Grid2>
                            <Grid2 size={{ xs: 4, sm: 2 }} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        fileUploadRef.current.value = null
                                        fileUploadRef.current.click()
                                    }}
                                >
                                    {i18n.t("contactListItems.buttons.import")}
                                </Button>
                            </Grid2>
                            <Grid2 size={{ xs: 4, sm: 2 }} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenContactListItemModal}
                                >
                                    {i18n.t("contactListItems.buttons.add")}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <>
                    <input
                        style={{ display: "none" }}
                        id="upload"
                        name="file"
                        type="file"
                        accept=".xls,.xlsx"
                        onChange={() => {
                            setConfirmOpen(true)
                        }}
                        ref={fileUploadRef}
                    />
                </>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ width: "0%" }}>
                                #
                            </TableCell>
                            <TableCell>
                                {i18n.t("contactListItems.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("contactListItems.table.number")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("contactListItems.table.email")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("contactListItems.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {contacts.map((contact) => (
                                <TableRow key={contact.id}>
                                    <TableCell
                                        align="center"
                                        style={{ width: "0%" }}
                                    >
                                        <IconButton>
                                            {contact.isWhatsappValid ? (
                                                <CheckCircleIcon
                                                    titleAccess="Whatsapp Válido"
                                                    htmlColor="green"
                                                />
                                            ) : (
                                                <BlockIcon
                                                    titleAccess="Whatsapp Inválido"
                                                    htmlColor="grey"
                                                />
                                            )}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{contact.name}</TableCell>
                                    <TableCell align="center">
                                        {contact.number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {contact.email}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                hadleEditContact(contact.id)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <Can
                                            role={user.profile}
                                            perform="contacts-page:deleteContact"
                                            yes={() => (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setConfirmOpen(true)
                                                        setDeletingContact(
                                                            contact,
                                                        )
                                                    }}
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default ContactListItems
