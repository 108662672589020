import * as Yup from "yup"
import checkoutFormModel from "./checkoutFormModel"
const {
    formField: { firstName, address1, city, zipcode, country },
} = checkoutFormModel

export default [
    Yup.object().shape({
        [firstName.name]: Yup.string().required(
            `${firstName.requiredErrorMsg}`,
        ),
        [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
        [city.name]: Yup.string()
            .nullable()
            .required(`${city.requiredErrorMsg}`),
        [zipcode.name]: Yup.string().required(`${zipcode.requiredErrorMsg}`),

        [country.name]: Yup.string()
            .nullable()
            .required(`${country.requiredErrorMsg}`),
    }),
]
