import React from "react"
import { SuccessContent, Total } from "./style"

import { Button } from "@mui/material"

function CheckoutSuccess(props) {
    const { data, onComplete } = props

    return (
        <React.Fragment>
            <Total>
                <p>
                    <span>TOTAL</span>
                </p>
                <p>
                    <strong>
                        R${" "}
                        {data.chargeTotal.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                        })}
                    </strong>
                </p>
            </Total>
            <SuccessContent>
                <p>
                    Para completar sua assinatura clique no link abaixo para ir
                    para a página de pagamento.
                </p>

                <Button
                    variant="contained"
                    color="primary"
                    href={data.paymentUrl}
                    target="_blank"
                    onClick={onComplete}
                    fullWidth
                >
                    PAGAR
                </Button>
            </SuccessContent>
        </React.Fragment>
    )
}

export default CheckoutSuccess
