import React, { useState, useEffect } from "react"

import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"
import Grid2 from "@mui/material/Grid2"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// ICONS
import CallIcon from "@mui/icons-material/Call"

import GroupAddIcon from "@mui/icons-material/GroupAdd"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm"
import TimerIcon from "@mui/icons-material/Timer"

import { makeStyles } from "@mui/styles"
import { grey, blue } from "@mui/material/colors"
import { toast } from "react-toastify"

import Chart from "./Chart"
import ButtonWithSpinner from "../../components/ButtonWithSpinner"

import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus"
import { isArray } from "lodash"

import useDashboard from "../../hooks/useDashboard"

import { isEmpty } from "lodash"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        height: 240,
        overflowY: "auto",
        ...theme.scrollbarStyles,
    },
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: "18px",
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: "14px",
    },
    alignRight: {
        textAlign: "right",
    },
    fullWidth: {
        width: "100%",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },
    card1: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#0094bb",
        color: "#eee",
    },
    card2: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#748e9d",
        color: "#eee",
    },
    card3: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#7f78e6",
        color: "#eee",
    },
    card4: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#cc991b",
        color: "#eee",
    },
    card5: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#434243",
        color: "#eee",
    },
    card6: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#b87d77",
        color: "#eee",
    },
    card7: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#7bc780",
        color: "#eee",
    },
    card8: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#b05c38",
        color: "#eee",
    },
    card9: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#bd3c58",
        color: "#eee",
    },
}))

const Dashboard = () => {
    const classes = useStyles()
    const [counters, setCounters] = useState({})
    const [attendants, setAttendants] = useState([])
    const [filterType, setFilterType] = useState(1)
    const [period, setPeriod] = useState(0)
    const [dateFrom, setDateFrom] = useState(
        moment("1", "D").format("YYYY-MM-DD"),
    )

    const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"))
    const [loading, setLoading] = useState(false)
    const { find } = useDashboard()

    useEffect(() => {
        async function firstLoad() {
            await fetchData()
        }
        setTimeout(() => {
            firstLoad()
        }, 1000)
    }, [])

    async function handleChangePeriod(value) {
        setPeriod(value)
    }

    async function handleChangeFilterType(value) {
        setFilterType(value)
        if (value === 1) {
            setPeriod(0)
        } else {
            setDateFrom("")
            setDateTo("")
        }
    }

    async function fetchData() {
        setLoading(true)

        let params = {}

        if (period > 0) {
            params = {
                days: period,
            }
        }

        if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
            params = {
                ...params,
                date_from: moment(dateFrom).format("YYYY-MM-DD"),
            }
        }

        if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
            params = {
                ...params,
                date_to: moment(dateTo).format("YYYY-MM-DD"),
            }
        }

        if (Object.keys(params).length === 0) {
            toast.error("Parametrize o filtro")
            setLoading(false)
            return
        }

        const data = await find(params)

        setCounters(data.counters)
        if (isArray(data.attendants)) {
            setAttendants(data.attendants)
        } else {
            setAttendants([])
        }

        setLoading(false)
    }

    function formatTime(minutes) {
        return moment()
            .startOf("day")
            .add(minutes, "minutes")
            .format("HH[h] mm[m]")
    }

    function renderFilters() {
        if (filterType === 1) {
            return (
                <>
                    <Grid2 size={{ xs: 12, sm: 6 }}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                            className={classes.fullWidth}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6 }}>
                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                            className={classes.fullWidth}
                        />
                    </Grid2>
                </>
            )
        } else {
            return (
                <Grid2 container size={{ xs: 12, sm: 6 }}>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="period-selector-label">
                            Período
                        </InputLabel>
                        <Select
                            labelId="period-selector-label"
                            id="period-selector"
                            value={period}
                            onChange={(e) => handleChangePeriod(e.target.value)}
                        >
                            <MenuItem value={0}>Nenhum selecionado</MenuItem>
                            <MenuItem value={3}>Últimos 3 dias</MenuItem>
                            <MenuItem value={7}>Últimos 7 dias</MenuItem>
                            <MenuItem value={15}>Últimos 15 dias</MenuItem>
                            <MenuItem value={30}>Últimos 30 dias</MenuItem>
                            <MenuItem value={60}>Últimos 60 dias</MenuItem>
                            <MenuItem value={90}>Últimos 90 dias</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
            )
        }
    }

    const Item = ({ children }) => (
        <Grid2
            size={{
                xs: 12,
                sm: 6,
                md: 4,
            }}
        >
            {children}
        </Grid2>
    )

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid2 container spacing={2} justifyContent="center">
                <Item>
                    <Paper
                        className={classes.card1}
                        style={{ overflow: "hidden" }}
                        elevation={4}
                    >
                        <Grid2 container spacing={3}>
                            <Grid2 item size={{ xs: 8 }}>
                                <Typography component="h3" variant="h6">
                                    Atd. Pendentes
                                </Typography>
                                <Grid2 item>
                                    <Typography component="h1" variant="h4">
                                        {counters.supportPending}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 item size={{ xs: 2 }}>
                                <CallIcon
                                    style={{
                                        fontSize: 100,
                                        color: "#0b708c",
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Item>
                <Item>
                    <Paper
                        className={classes.card2}
                        style={{ overflow: "hidden" }}
                        elevation={6}
                    >
                        <Grid2 container spacing={3}>
                            <Grid2 item size={{ xs: 8 }}>
                                <Typography component="h3" variant="h6">
                                    Atd. Acontecendo
                                </Typography>
                                <Grid2 item>
                                    <Typography component="h1" variant="h4">
                                        {counters.supportHappening}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 item size={{ xs: 4 }}>
                                <HourglassEmptyIcon
                                    style={{
                                        fontSize: 100,
                                        color: "#47606e",
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Item>
                <Item>
                    <Paper
                        className={classes.card3}
                        style={{ overflow: "hidden" }}
                        elevation={6}
                    >
                        <Grid2 container spacing={3}>
                            <Grid2 item size={{ xs: 8 }}>
                                <Typography component="h3" variant="h6">
                                    Finalizados
                                </Typography>
                                <Grid2 item>
                                    <Typography component="h1" variant="h4">
                                        {counters.supportFinished}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 item size={{ xs: 4 }}>
                                <CheckCircleIcon
                                    style={{
                                        fontSize: 100,
                                        color: "#5852ab",
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Item>
                <Item>
                    <Paper
                        className={classes.card4}
                        style={{ overflow: "hidden" }}
                        elevation={6}
                    >
                        <Grid2 container spacing={3}>
                            <Grid2 item size={{ xs: 8 }}>
                                <Typography component="h3" variant="h6">
                                    Novos Contatos
                                </Typography>
                                <Grid2 item>
                                    <Typography component="h1" variant="h4">
                                        {counters.leads}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 item size={{ xs: 4 }}>
                                <GroupAddIcon
                                    style={{
                                        fontSize: 100,
                                        color: "#8c6b19",
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Item>
                <Item>
                    <Paper
                        className={classes.card8}
                        style={{ overflow: "hidden" }}
                        elevation={6}
                    >
                        <Grid2 container spacing={3}>
                            <Grid2 item size={{ xs: 8 }}>
                                <Typography component="h3" variant="h6">
                                    T.M. de Atendimento
                                </Typography>
                                <Grid2 item>
                                    <Typography component="h1" variant="h4">
                                        {formatTime(counters.avgSupportTime)}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 item size={{ xs: 4 }}>
                                <AccessAlarmIcon
                                    style={{
                                        fontSize: 100,
                                        color: "#7a3f26",
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Item>
                <Item>
                    <Paper
                        className={classes.card9}
                        style={{ overflow: "hidden" }}
                        elevation={6}
                    >
                        <Grid2 container spacing={3}>
                            <Grid2 item size={{ xs: 8 }}>
                                <Typography component="h3" variant="h6">
                                    T.M. de Espera
                                </Typography>
                                <Grid2 item>
                                    <Typography component="h1" variant="h4">
                                        {formatTime(counters.avgWaitTime)}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 item size={{ xs: 4 }}>
                                <TimerIcon
                                    style={{
                                        fontSize: 100,
                                        color: "#8a2c40",
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Item>
            </Grid2>

            <Grid2
                container
                spacing={2}
                marginTop={5}
                flexDirection="row"
                flexWrap={"nowrap"}
                justifyContent="center"
            >
                <FormControl className={classes.selectContainer}>
                    <InputLabel id="period-selector-label">
                        Tipo de Filtro
                    </InputLabel>
                    <Select
                        labelId="period-selector-label"
                        value={filterType}
                        onChange={(e) => handleChangeFilterType(e.target.value)}
                    >
                        <MenuItem value={1}>Filtro por Data</MenuItem>
                        <MenuItem value={2}>Filtro por Período</MenuItem>
                    </Select>
                </FormControl>

                {renderFilters()}
            </Grid2>

            <Grid2
                size={{
                    xs: 12,
                }}
                className={classes.alignRight}
                marginTop={2}
            >
                <ButtonWithSpinner
                    loading={loading}
                    onClick={() => fetchData()}
                    variant="contained"
                    color="primary"
                >
                    Filtrar
                </ButtonWithSpinner>
            </Grid2>

            <Grid2
                size={{
                    xs: 12,
                }}
            >
                <Paper className={classes.fixedHeightPaper}>
                    <Chart />
                </Paper>
            </Grid2>

            <Grid2
                size={{
                    xs: 12,
                }}
            >
                {attendants.length ? (
                    <TableAttendantsStatus
                        attendants={attendants}
                        loading={loading}
                    />
                ) : null}
            </Grid2>
        </Container>
    )
}

export default Dashboard
