import React, { useState, useEffect, useContext, useRef } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

import { makeStyles } from "@mui/styles"
import { green } from "@mui/material/colors"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import UploadIcon from "@mui/icons-material/AttachFile"
import CircularProgress from "@mui/material/CircularProgress"

import { i18n } from "../../translate/i18n"

import api from "../../services/api"
import toastError from "../../errors/toastError"
import { Chip, FormControl } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import moment from "moment"
import { AuthContext } from "../../context/Auth/AuthContext"
import { isArray, capitalize, head } from "lodash"
import ModalSelectAudio from "../ModalSelectAudio"

import { getFile } from "../../services/firebase"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    uploadButtonHolder: {
        paddingTop: "10px",
        paddingBottom: "10px",
    },
}))

const ScheduleSchema = Yup.object().shape({
    body: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
    contactId: Yup.number().required("Obrigatório"),
    sendAt: Yup.string().required("Obrigatório"),
})

const ScheduleModal = ({
    open,
    onClose,
    scheduleId,
    contactId,
    cleanContact,
    reload,
}) => {
    const attachmentFile = useRef(null)
    const classes = useStyles()
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const [file, setFile] = useState(null)
    const [modalSendRecordedAudio, setModalSendRecordedAudio] = useState(false)

    const initialState = {
        body: "",
        contactId: "",
        sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
        sentAt: "",
    }

    const initialContact = {
        id: "",
        name: "",
    }

    const [schedule, setSchedule] = useState(initialState)
    const [currentContact, setCurrentContact] = useState(initialContact)
    const [contacts, setContacts] = useState([initialContact])

    useEffect(() => {
        if (contactId && contacts.length) {
            const contact = contacts.find((c) => c.id === contactId)
            if (contact) {
                setCurrentContact(contact)
            }
        }
    }, [contactId, contacts])

    useEffect(() => {
        const { companyId } = user
        if (open) {
            try {
                ;(async () => {
                    const { data: contactList } = await api.get(
                        "/contacts/list",
                        { params: { companyId: companyId } },
                    )
                    let customList = contactList.map((c) => ({
                        id: c.id,
                        name: c.name,
                    }))
                    if (isArray(customList)) {
                        setContacts([{ id: "", name: "" }, ...customList])
                    }
                    if (contactId) {
                        setSchedule((prevState) => {
                            return { ...prevState, contactId }
                        })
                    }

                    if (!scheduleId) return

                    const { data } = await api.get(`/schedules/${scheduleId}`)
                    setSchedule((prevState) => {
                        return {
                            ...prevState,
                            ...data,
                            sendAt: moment(data.sendAt).format(
                                "YYYY-MM-DDTHH:mm",
                            ),
                        }
                    })
                    setCurrentContact(data.contact)
                })()
            } catch (err) {
                toastError(err)
            }
        }
    }, [scheduleId, contactId, open, user])

    const handleClose = () => {
        onClose()
        setSchedule(initialState)
    }

    const handleSaveSchedule = async (values) => {
        const scheduleData = { ...values, userId: user.id }
        try {
            let response
            if (scheduleId) {
                response = await api.put(
                    `/schedules/${scheduleId}`,
                    scheduleData,
                )
            } else {
                response = await api.post("/schedules", scheduleData)
            }
            if (file && response) {
                const formData = new FormData()
                formData.append("file", file)
                await api.post(
                    `/schedules/${response.data.id}/media-upload`,
                    formData,
                )
                toast.success("Arquivo enviado com sucesso!")
            }
            toast.success(i18n.t("scheduleModal.success"))
            if (typeof reload == "function") {
                reload()
            }
            if (contactId) {
                if (typeof cleanContact === "function") {
                    cleanContact()
                    history.push("/schedules")
                }
            }
            attachmentFile.current.value = ""
        } catch (err) {
            attachmentFile.current.value = ""
            toastError(err)
        }
        setCurrentContact(initialContact)
        setSchedule(initialState)
        handleClose()
    }

    const handleAttachmentFile = async (e) => {
        const attachment = head(e.target.files)
        setFile(attachment)
    }

    const handleAttachAudio = async (path) => {
        const url = await getFile(path)
        fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const fi = new File([blob], `${Date.now()}.mp3`, {
                    type: "audio/mpeg",
                })
                setFile(fi)
                setModalSendRecordedAudio(false)
            })
    }

    return (
        <div className={classes.root}>
            <ModalSelectAudio
                open={modalSendRecordedAudio}
                onSelecteAudio={(audio) => handleAttachAudio(audio)}
                handleClose={() => setModalSendRecordedAudio(false)}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {schedule.status === "ERRO"
                        ? "Erro de Envio"
                        : `Mensagem ${capitalize(schedule.status)}`}
                </DialogTitle>
                <div style={{ display: "none" }}>
                    <input
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        ref={attachmentFile}
                        onChange={handleAttachmentFile}
                    />
                </div>

                <Formik
                    initialValues={schedule}
                    enableReinitialize={true}
                    validationSchema={ScheduleSchema}
                    onSubmit={(values, actions) => {
                        handleSaveSchedule(values)
                        actions.setSubmitting(false)
                    }}
                >
                    {({ touched, errors, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                            fullWidth
                                            value={currentContact}
                                            options={contacts}
                                            onChange={(e, contact) => {
                                                const contactId = contact
                                                    ? contact.id
                                                    : ""
                                                setSchedule({
                                                    ...schedule,
                                                    contactId,
                                                })
                                                setCurrentContact(
                                                    contact
                                                        ? contact
                                                        : initialContact,
                                                )
                                            }}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            getOptionSelected={(
                                                option,
                                                value,
                                            ) => {
                                                return value.id === option.id
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Contato"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        rows={9}
                                        multiline={true}
                                        label={i18n.t(
                                            "scheduleModal.form.body",
                                        )}
                                        name="body"
                                        error={
                                            touched.body && Boolean(errors.body)
                                        }
                                        helperText={touched.body && errors.body}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t(
                                            "scheduleModal.form.sendAt",
                                        )}
                                        type="datetime-local"
                                        name="sendAt"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={
                                            touched.sendAt &&
                                            Boolean(errors.sendAt)
                                        }
                                        helperText={
                                            touched.sendAt && errors.sendAt
                                        }
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.uploadButtonHolder}>
                                    {file ? (
                                        <Chip
                                            icon={<UploadIcon />}
                                            label={file.name}
                                            onDelete={() => setFile(null)}
                                        />
                                    ) : null}
                                    {!file && (
                                        <>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                onClick={() => {
                                                    attachmentFile.current.click()
                                                }}
                                                startIcon={<UploadIcon />}
                                                variant="outlined"
                                                className={classes.addButton}
                                            >
                                                Anexar arquivo
                                            </Button>
                                            <Button
                                                color="primary"
                                                size="small"
                                                onClick={() =>
                                                    setModalSendRecordedAudio(
                                                        true,
                                                    )
                                                }
                                                startIcon={<UploadIcon />}
                                                variant="outlined"
                                                className={classes.addButton}
                                                style={{ marginLeft: "6px" }}
                                            >
                                                Anexar áudio
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("scheduleModal.buttons.cancel")}
                                </Button>
                                {(schedule.sentAt === null ||
                                    schedule.sentAt === "") && (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {scheduleId
                                            ? `${i18n.t(
                                                  "scheduleModal.buttons.okEdit",
                                              )}`
                                            : `${i18n.t(
                                                  "scheduleModal.buttons.okAdd",
                                              )}`}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default ScheduleModal
