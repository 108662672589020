import React, { useState, useEffect } from "react"

import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import IconButton from "@mui/material/IconButton"

import SendSharp from "@mui/icons-material/SendSharp"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"

import api from "../../services/api"
import { i18n } from "../../translate/i18n"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import toastError from "../../errors/toastError"
import moment from "moment"
import { green } from "@mui/material/colors"
import DialogActions from "@mui/material/DialogActions"
import { CircularProgress } from "@mui/material"

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    uploadButtonHolder: {
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    buttonIcon: {
        display: "flex",
        justifyContent: "center",
    },
    sendMessageIcons: {
        marginLeft: "auto",
        marginRight: "auto",
    },
    center: {
        display: "flex",
        justifyContent: "center",
    },
    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },
}))

export default function ModalSelectAudio({
    open,
    handleClose,
    ticketId,
    onSelecteAudio,
}) {
    const classes = useStyles()
    const [audios, setAudios] = useState([])
    const [loading, setLoading] = useState(false)

    async function sendAudio({ id }) {
        setLoading(true)
        const response = await api.post(`/audios/${id}/send/${ticketId}`)
        if (response.status === 200) {
            setTimeout(() => {
                handleClose()
            }, 500)
        }
        setLoading(false)
    }
    async function getAudios() {
        try {
            setLoading(true)
            const { data } = await api.get("/audios")
            setAudios(data.audios)
            setLoading(false)
        } catch (err) {
            toastError(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (open) {
            getAudios()
        }
    }, [open])

    const getMediaPath = (filename) => {
        return filename
    }

    const handleSelectAudio = async (path) => {
        onSelecteAudio(path)
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    Selecionar audio
                </DialogTitle>

                <div className={classes.center}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Nome</TableCell>
                                <TableCell align="center">Ouvir</TableCell>
                                <TableCell align="center">Gravado</TableCell>
                                <TableCell align="center">Enviar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {!loading &&
                                    audios.map((audio) => (
                                        <TableRow key={audio.id}>
                                            <TableCell
                                                align="center"
                                                title={audio.body}
                                            >
                                                {audio.mediaName}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                title={audio.body}
                                            >
                                                <audio
                                                    controls
                                                    src={getMediaPath(
                                                        audio.mediaPath,
                                                    )}
                                                >
                                                    <source
                                                        src={getMediaPath(
                                                            audio.mediaPath,
                                                        )}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </TableCell>
                                            <TableCell align="center">
                                                {moment(audio.sendAt).format(
                                                    "DD/MM/YYYY HH:mm:ss",
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        if (onSelecteAudio) {
                                                            handleSelectAudio(
                                                                getMediaPath(
                                                                    audio.firebasePath,
                                                                ),
                                                            )
                                                        } else {
                                                            sendAudio({
                                                                id: audio.id,
                                                            })
                                                        }
                                                    }}
                                                >
                                                    <SendSharp />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {loading && <TableRowSkeleton columns={4} />}
                            </>
                        </TableBody>
                    </Table>
                </div>

                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            `${i18n.t("scheduleModal.buttons.cancel")}`
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
