import { Box, Chip, TextField } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import React, { useEffect, useState } from "react"
import toastError from "../../errors/toastError"
import api from "../../services/api"

export function TagsFilter({ onFiltered }) {
    const [tags, setTags] = useState([])
    const [selecteds, setSelecteds] = useState([])

    useEffect(() => {
        async function fetchData() {
            await loadTags()
        }
        fetchData()
    }, [])

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`)
            setTags(data)
        } catch (err) {
            toastError(err)
        }
    }

    const onChange = async (value) => {
        setSelecteds(value)
        onFiltered(value)
    }

    return (
        <Box style={{ padding: 10 }}>
            <Autocomplete
                multiple
                size="small"
                options={tags}
                value={selecteds}
                onChange={(e, v) => onChange(v)}
                getOptionLabel={(option) => option.name}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={index}
                            variant="outlined"
                            style={{
                                backgroundColor: option.color || "#eee",
                                textShadow: "1px 1px 1px #000",
                                color: "white",
                            }}
                            label={option.name}
                            {...getTagProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Filtro por Tags"
                    />
                )}
            />
        </Box>
    )
}
