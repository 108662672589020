import React from "react"
import { useParams } from "react-router-dom"
import Grid2 from "@mui/material/Grid2"
import Paper from "@mui/material/Paper"
import { makeStyles } from "@mui/styles"

import TicketsManager from "../../components/TicketsManagerTabs/"
import Ticket from "../../components/Ticket/"

import { i18n } from "../../translate/i18n"

const useStyles = makeStyles((theme) => ({
    chatContainer: {
        flex: 1,
        // backgroundColor: "#eee",
        padding: theme.spacing(4),
        height: `calc(100% - 48px)`,
        overflowY: "hidden",
    },

    chatPapper: {
        display: "flex",
        height: "100%",
    },

    contactsWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflowY: "hidden",
    },
    messagesWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
    },
    welcomeMsg: {
        background: theme.palette.total,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
    },
}))

const TicketsCustom = () => {
    const classes = useStyles()
    const { ticketId } = useParams()

    return (
        <div className={classes.chatContainer}>
            <div className={classes.chatPapper}>
                <Grid2 container spacing={0} sx={{ width: "100%" }}>
                    <Grid2
                        item
                        size={{
                            xs: 4,
                        }}
                        className={classes.contactsWrapper}
                    >
                        <TicketsManager />
                    </Grid2>
                    <Grid2
                        item
                        size={{
                            xs: 8,
                        }}
                        className={classes.messagesWrapper}
                    >
                        {ticketId ? (
                            <>
                                <Ticket />
                            </>
                        ) : (
                            <Paper
                                square
                                variant="outlined"
                                className={classes.welcomeMsg}
                            >
                                <span>{i18n.t("chat.noTicketMessage")}</span>
                            </Paper>
                        )}
                    </Grid2>
                </Grid2>
            </div>
        </div>
    )
}

export default TicketsCustom
