import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"

import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import { makeStyles } from "@mui/styles"

import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"

import { i18n } from "../../translate/i18n"
import api from "../../services/api"
import ButtonWithSpinner from "../ButtonWithSpinner"
import toastError from "../../errors/toastError"
import useQueues from "../../hooks/useQueues"

const useStyles = makeStyles(() => ({
    maxWidth: {
        width: "100%",
    },
}))

const filterOptions = createFilterOptions({
    trim: true,
})

const TransferTicketModalCustom = ({ modalOpen, onClose, ticketid }) => {
    const history = useHistory()
    const [options, setOptions] = useState([])
    const [queues, setQueues] = useState([])
    const [allQueues, setAllQueues] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchParam, setSearchParam] = useState("")
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedQueue, setSelectedQueue] = useState("")
    const classes = useStyles()
    const { findAll: findAllQueues } = useQueues()
    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (isMounted.current) {
            const loadQueues = async () => {
                const list = await findAllQueues()
                setAllQueues(list)
                setQueues(list)
            }
            loadQueues()
        }
    }, [])

    useEffect(() => {
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false)
            return
        }
        const delayDebounceFn = setTimeout(() => {
            setLoading(true)
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get("/users/", {
                        params: { searchParam },
                    })
                    setOptions(data.users)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }

            fetchUsers()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, modalOpen])

    const handleClose = () => {
        onClose()
        setSearchParam("")
        setSelectedUser(null)
    }

    const handleSaveTicket = async (e) => {
        e.preventDefault()
        if (!ticketid) return
        if (!selectedQueue || selectedQueue === "") return
        setLoading(true)
        try {
            let data = {}

            if (selectedUser) {
                data.userId = selectedUser.id
            }

            if (selectedQueue && selectedQueue !== null) {
                data.queueId = selectedQueue

                if (!selectedUser) {
                    data.status = "pending"
                    data.userId = null
                }
            }

            await api.put(`/tickets/${ticketid}`, data)

            history.push(`/tickets`)
        } catch (err) {
            setLoading(false)
            toastError(err)
        }
    }

    return (
        <Dialog
            open={modalOpen}
            onClose={handleClose}
            maxWidth="lg"
            scroll="paper"
        >
            <form onSubmit={handleSaveTicket}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("transferTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    <Autocomplete
                        style={{ width: 300, marginBottom: 20 }}
                        getOptionLabel={(option) => `${option.name}`}
                        onChange={(e, newValue) => {
                            setSelectedUser(newValue)
                            if (
                                newValue != null &&
                                Array.isArray(newValue.queues)
                            ) {
                                setQueues(newValue.queues)
                            } else {
                                setQueues(allQueues)
                                setSelectedQueue("")
                            }
                        }}
                        options={options}
                        filterOptions={filterOptions}
                        freeSolo
                        autoHighlight
                        noOptionsText={i18n.t("transferTicketModal.noOptions")}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={i18n.t("transferTicketModal.fieldLabel")}
                                variant="outlined"
                                autoFocus
                                onChange={(e) => setSearchParam(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <FormControl
                        variant="outlined"
                        className={classes.maxWidth}
                    >
                        <InputLabel>
                            {i18n.t("transferTicketModal.fieldQueueLabel")}
                        </InputLabel>
                        <Select
                            value={selectedQueue}
                            onChange={(e) => setSelectedQueue(e.target.value)}
                            label={i18n.t(
                                "transferTicketModal.fieldQueuePlaceholder",
                            )}
                        >
                            {queues.map((queue) => (
                                <MenuItem key={queue.id} value={queue.id}>
                                    {queue.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("transferTicketModal.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="submit"
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t("transferTicketModal.buttons.ok")}
                    </ButtonWithSpinner>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default TransferTicketModalCustom
