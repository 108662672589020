import React, { useState, useEffect, useRef, useContext } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"

import { makeStyles } from "@mui/styles"
import { green } from "@mui/material/colors"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"

import { i18n } from "../../translate/i18n"

import api from "../../services/api"
import toastError from "../../errors/toastError"
import { AuthContext } from "../../context/Auth/AuthContext"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}))

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
    email: Yup.string().email("Invalid email"),
})

const LeadModal = ({ open, onClose, leadId, initialValues, onSave }) => {
    const classes = useStyles()
    const isMounted = useRef(true)
    const [subsidiaries, setSubsidiaries] = useState([])

    const { user } = useContext(AuthContext)
    const { profile } = user

    const initialState = {
        name: "",
        number: "",
        email: "",
        referenceId: "",
        pipeline: "",
        refInterest: "",
        origin: "",
        subsidiaryId: null,
    }

    const [contact, setContact] = useState(initialState)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                const subsidiariesList = await api.get("/subsidiaries")
                setSubsidiaries(subsidiariesList.data)
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        const fetchContact = async () => {
            if (initialValues) {
                setContact((prevState) => {
                    return { ...prevState, ...initialValues }
                })
            }

            if (!leadId) return

            try {
                const { data } = await api.get(`/leads/${leadId}`)
                if (isMounted.current) {
                    setContact(data)
                }
            } catch (err) {
                toastError(err)
            }
        }

        fetchContact()
    }, [leadId, open, initialValues])

    const handleClose = () => {
        onClose()
        setContact(initialState)
    }

    const handleSaveContact = async (values) => {
        try {
            if (leadId) {
                await api.put(`/leads/update/${leadId}`, values)
                handleClose()
            } else {
                const { data } = await api.post("/leads/create", values)
                if (onSave) {
                    onSave(data)
                }
                handleClose()
            }
            toast.success("Lead salvo com sucesso")
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {leadId ? `Editar lead` : `Adicionar lead`}
                </DialogTitle>
                <Formik
                    initialValues={contact}
                    enableReinitialize={true}
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveContact(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <Typography variant="subtitle1" gutterBottom>
                                    Detalhes do lead
                                </Typography>
                                <Field
                                    as={TextField}
                                    label={i18n.t("contactModal.form.name")}
                                    name="name"
                                    autoFocus
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                />
                                <Field
                                    as={TextField}
                                    label={i18n.t("contactModal.form.number")}
                                    name="number"
                                    error={
                                        touched.number && Boolean(errors.number)
                                    }
                                    helperText={touched.number && errors.number}
                                    placeholder="5513912344321"
                                    variant="outlined"
                                    margin="dense"
                                />
                                <div>
                                    <Field
                                        as={TextField}
                                        label={i18n.t(
                                            "contactModal.form.email",
                                        )}
                                        name="email"
                                        error={
                                            touched.email &&
                                            Boolean(errors.email)
                                        }
                                        helperText={
                                            touched.email && errors.email
                                        }
                                        placeholder="Email address"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </div>

                                {profile === "admin" &&
                                    // eslint-disable-next-line no-restricted-globals
                                    location.href.includes(
                                        "synccrm.com.br",
                                    ) && (
                                        <FormControl size="small" fullWidth>
                                            <InputLabel htmlFor="plan-selection">
                                                Filial
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                variant="outlined"
                                                fullWidth
                                                id="plan-selection"
                                                label="Filial"
                                                name="subsidiaryId"
                                                required
                                                size="small"
                                            >
                                                {subsidiaries?.map(
                                                    (subsidiary, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={
                                                                subsidiary?.id
                                                            }
                                                            style={{
                                                                paddingTop: 10.5,
                                                                paddingBottom: 10.5,
                                                            }}
                                                        >
                                                            {subsidiary?.name} -
                                                            Tipo de negócio:{" "}
                                                            {
                                                                subsidiary?.typeBusiness
                                                            }{" "}
                                                            - UUID:{" "}
                                                            {subsidiary?.uuid}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Field>
                                        </FormControl>
                                    )}

                                <div>
                                    <Field
                                        as={TextField}
                                        label="Código"
                                        name="referenceId"
                                        error={
                                            touched.referenceId &&
                                            Boolean(errors.referenceId)
                                        }
                                        helperText={
                                            touched.referenceId &&
                                            errors.referenceId
                                        }
                                        placeholder="Código do Lead"
                                        margin="dense"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                    <Field
                                        as={TextField}
                                        label="Origem"
                                        name="origin"
                                        error={
                                            touched.origin &&
                                            Boolean(errors.origin)
                                        }
                                        helperText={
                                            touched.origin && errors.origin
                                        }
                                        placeholder="Origem do lead"
                                        margin="dense"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                </div>

                                <div>
                                    <Field
                                        as={TextField}
                                        label="Pipeline"
                                        name="pipeline"
                                        error={
                                            touched.pipeline &&
                                            Boolean(errors.pipeline)
                                        }
                                        helperText={
                                            touched.pipeline && errors.pipeline
                                        }
                                        placeholder="Pipeline do Lead"
                                        margin="dense"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                    <Field
                                        as={TextField}
                                        label="Ref Interesse"
                                        name="refInterest"
                                        error={
                                            touched.refInterest &&
                                            Boolean(errors.refInterest)
                                        }
                                        helperText={
                                            touched.refInterest &&
                                            errors.refInterest
                                        }
                                        placeholder="Ref Interesse"
                                        margin="dense"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("contactModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {leadId
                                        ? `${i18n.t(
                                              "contactModal.buttons.okEdit",
                                          )}`
                                        : `${i18n.t(
                                              "contactModal.buttons.okAdd",
                                          )}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default LeadModal
