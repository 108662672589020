import React, { useState, useEffect, useRef, useContext } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"
import { head } from "lodash"

import { makeStyles } from "@mui/styles"
import { green } from "@mui/material/colors"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"

import { i18n } from "../../translate/i18n"
import moment from "moment"

import api from "../../services/api"
import toastError from "../../errors/toastError"
import {
    Box,
    FormControl,
    Grid2,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
} from "@mui/material"
import { AuthContext } from "../../context/Auth/AuthContext"
import ConfirmationModal from "../ConfirmationModal"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },

    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}))

const CampaignSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
})

const CampaignModal = ({
    open,
    onClose,
    campaignId,
    initialValues,
    onSave,
    resetPagination,
}) => {
    const classes = useStyles()
    const isMounted = useRef(true)
    const { user } = useContext(AuthContext)
    const { companyId } = user

    const initialState = {
        name: "",
        message1: "",
        message2: "",
        message3: "",
        message4: "",
        message5: "",
        confirmationMessage1: "",
        confirmationMessage2: "",
        confirmationMessage3: "",
        confirmationMessage4: "",
        confirmationMessage5: "",
        status: "INATIVA", // INATIVA, PROGRAMADA, EM_ANDAMENTO, CANCELADA, FINALIZADA,
        confirmation: false,
        scheduledAt: "",
        whatsappId: "",
        contactListId: "",
        companyId,
    }

    const [campaign, setCampaign] = useState(initialState)
    const [whatsapps, setWhatsapps] = useState([])
    const [contactLists, setContactLists] = useState([])
    const [messageTab, setMessageTab] = useState(0)
    const [attachment, setAttachment] = useState(null)
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [campaignEditable, setCampaignEditable] = useState(true)
    const attachmentFile = useRef(null)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (isMounted.current) {
            if (initialValues) {
                setCampaign((prevState) => {
                    return { ...prevState, ...initialValues }
                })
            }

            api.get(`/contact-lists/list`, { params: { companyId } }).then(
                ({ data }) => setContactLists(data),
            )

            api.get(`/whatsapp`, { params: { companyId, session: 0 } }).then(
                ({ data }) => setWhatsapps(data),
            )

            if (!campaignId) return

            api.get(`/campaigns/${campaignId}`).then(({ data }) => {
                setCampaign((prev) => {
                    let prevCampaignData = Object.assign({}, prev)

                    Object.entries(data).forEach(([key, value]) => {
                        if (
                            key === "scheduledAt" &&
                            value !== "" &&
                            value !== null
                        ) {
                            prevCampaignData[key] =
                                moment(value).format("YYYY-MM-DDTHH:mm")
                        } else {
                            prevCampaignData[key] = value === null ? "" : value
                        }
                    })

                    return prevCampaignData
                })
            })
        }
    }, [campaignId, open, initialValues, companyId])

    useEffect(() => {
        const now = moment()
        const scheduledAt = moment(campaign.scheduledAt)
        const moreThenAnHour =
            !Number.isNaN(scheduledAt.diff(now)) &&
            scheduledAt.diff(now, "hour") > 1
        const isEditable =
            campaign.status === "INATIVA" ||
            (campaign.status === "PROGRAMADA" && moreThenAnHour)

        setCampaignEditable(isEditable)
    }, [campaign.status, campaign.scheduledAt])

    const handleClose = () => {
        onClose()
        setCampaign(initialState)
    }

    const handleAttachmentFile = (e) => {
        const file = head(e.target.files)
        if (file) {
            setAttachment(file)
        }
    }

    const handleSaveCampaign = async (values) => {
        try {
            const dataValues = {}
            Object.entries(values).forEach(([key, value]) => {
                if (key === "scheduledAt" && value !== "" && value !== null) {
                    dataValues[key] = moment(value).format(
                        "YYYY-MM-DD HH:mm:ss",
                    )
                } else {
                    dataValues[key] = value === "" ? null : value
                }
            })

            if (campaignId) {
                await api.put(`/campaigns/${campaignId}`, dataValues)

                if (attachment != null) {
                    const formData = new FormData()
                    formData.append("file", attachment)
                    await api.post(
                        `/campaigns/${campaignId}/media-upload`,
                        formData,
                    )
                }
                handleClose()
            } else {
                const { data } = await api.post("/campaigns", dataValues)

                if (attachment != null) {
                    const formData = new FormData()
                    formData.append("file", attachment)
                    await api.post(
                        `/campaigns/${data.id}/media-upload`,
                        formData,
                    )
                }
                if (onSave) {
                    onSave(data)
                }
                handleClose()
            }
            toast.success(i18n.t("campaigns.toasts.success"))
        } catch (err) {
            console.log(err)
            toastError(err)
        }
    }

    const deleteMedia = async () => {
        if (attachment) {
            setAttachment(null)
            attachmentFile.current.value = null
        }

        if (campaign.mediaPath) {
            await api.delete(`/campaigns/${campaign.id}/media-upload`)
            setCampaign((prev) => ({
                ...prev,
                mediaPath: null,
                mediaName: null,
            }))
            toast.success(i18n.t("campaigns.toasts.deleted"))
        }
    }

    const renderMessageField = (identifier) => {
        return (
            <Field
                as={TextField}
                id={identifier}
                name={identifier}
                fullWidth
                rows={5}
                label={i18n.t(`campaigns.dialog.form.${identifier}`)}
                placeholder={i18n.t("campaigns.dialog.form.messagePlaceholder")}
                multiline={true}
                variant="outlined"
                helperText="Utilize variáveis como {nome}, {numero}, {email} ou defina variáveis personalziadas."
                disabled={!campaignEditable && campaign.status !== "CANCELADA"}
            />
        )
    }

    const renderConfirmationMessageField = (identifier) => {
        return (
            <Field
                as={TextField}
                id={identifier}
                name={identifier}
                fullWidth
                rows={5}
                label={i18n.t(`campaigns.dialog.form.${identifier}`)}
                placeholder={i18n.t("campaigns.dialog.form.messagePlaceholder")}
                multiline={true}
                variant="outlined"
                disabled={!campaignEditable && campaign.status !== "CANCELADA"}
            />
        )
    }

    const cancelCampaign = async () => {
        try {
            await api.post(`/campaigns/${campaign.id}/cancel`)
            toast.success(i18n.t("campaigns.toasts.cancel"))
            setCampaign((prev) => ({ ...prev, status: "CANCELADA" }))
            resetPagination()
        } catch (err) {
            toast.error(err.message)
        }
    }

    const restartCampaign = async () => {
        try {
            await api.post(`/campaigns/${campaign.id}/restart`)
            toast.success(i18n.t("campaigns.toasts.restart"))
            setCampaign((prev) => ({ ...prev, status: "EM_ANDAMENTO" }))
            resetPagination()
        } catch (err) {
            toast.error(err.message)
        }
    }

    return (
        <div className={classes.root}>
            <ConfirmationModal
                title={i18n.t("campaigns.confirmationModal.deleteTitle")}
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirm={deleteMedia}
            >
                {i18n.t("campaigns.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {campaignEditable ? (
                        <>
                            {campaignId
                                ? `${i18n.t("campaigns.dialog.update")}`
                                : `${i18n.t("campaigns.dialog.new")}`}
                        </>
                    ) : (
                        <>{`${i18n.t("campaigns.dialog.readonly")}`}</>
                    )}
                </DialogTitle>
                <div style={{ display: "none" }}>
                    <input
                        type="file"
                        ref={attachmentFile}
                        onChange={(e) => handleAttachmentFile(e)}
                    />
                </div>
                <Formik
                    initialValues={campaign}
                    enableReinitialize={true}
                    validationSchema={CampaignSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveCampaign(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <Grid2 spacing={2} container>
                                    <Grid2
                                        size={{
                                            xs: 12,
                                            md: 9,
                                        }}
                                        item
                                    >
                                        <Field
                                            as={TextField}
                                            label={i18n.t(
                                                "campaigns.dialog.form.name",
                                            )}
                                            name="name"
                                            error={
                                                touched.name &&
                                                Boolean(errors.name)
                                            }
                                            helperText={
                                                touched.name && errors.name
                                            }
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.textField}
                                            disabled={!campaignEditable}
                                        />
                                    </Grid2>
                                    <Grid2
                                        size={{
                                            xs: 12,
                                            md: 3,
                                        }}
                                        item
                                    >
                                        <FormControl
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.formControl}
                                        >
                                            <InputLabel id="confirmation-selection-label">
                                                {i18n.t(
                                                    "campaigns.dialog.form.confirmation",
                                                )}
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                label={i18n.t(
                                                    "campaigns.dialog.form.confirmation",
                                                )}
                                                placeholder={i18n.t(
                                                    "campaigns.dialog.form.confirmation",
                                                )}
                                                labelId="confirmation-selection-label"
                                                id="confirmation"
                                                name="confirmation"
                                                error={
                                                    touched.confirmation &&
                                                    Boolean(errors.confirmation)
                                                }
                                                disabled={!campaignEditable}
                                            >
                                                <MenuItem value={false}>
                                                    Desabilitada
                                                </MenuItem>
                                                <MenuItem value={true}>
                                                    Habilitada
                                                </MenuItem>
                                            </Field>
                                        </FormControl>
                                    </Grid2>
                                    <Grid2
                                        size={{
                                            xs: 12,
                                            md: 4,
                                        }}
                                        item
                                    >
                                        <FormControl
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.formControl}
                                        >
                                            <InputLabel id="contactList-selection-label">
                                                {i18n.t(
                                                    "campaigns.dialog.form.contactList",
                                                )}
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                label={i18n.t(
                                                    "campaigns.dialog.form.contactList",
                                                )}
                                                placeholder={i18n.t(
                                                    "campaigns.dialog.form.contactList",
                                                )}
                                                labelId="contactList-selection-label"
                                                id="contactListId"
                                                name="contactListId"
                                                error={
                                                    touched.contactListId &&
                                                    Boolean(
                                                        errors.contactListId,
                                                    )
                                                }
                                                disabled={!campaignEditable}
                                            >
                                                <MenuItem value="">
                                                    Nenhuma
                                                </MenuItem>
                                                {contactLists &&
                                                    contactLists.map(
                                                        (contactList) => (
                                                            <MenuItem
                                                                key={
                                                                    contactList.id
                                                                }
                                                                value={
                                                                    contactList.id
                                                                }
                                                            >
                                                                {
                                                                    contactList.name
                                                                }
                                                            </MenuItem>
                                                        ),
                                                    )}
                                            </Field>
                                        </FormControl>
                                    </Grid2>
                                    <Grid2
                                        size={{
                                            xs: 12,
                                            md: 4,
                                        }}
                                        item
                                    >
                                        <FormControl
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.formControl}
                                        >
                                            <InputLabel id="whatsapp-selection-label">
                                                {i18n.t(
                                                    "campaigns.dialog.form.whatsapp",
                                                )}
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                label={i18n.t(
                                                    "campaigns.dialog.form.whatsapp",
                                                )}
                                                placeholder={i18n.t(
                                                    "campaigns.dialog.form.whatsapp",
                                                )}
                                                labelId="whatsapp-selection-label"
                                                id="whatsappId"
                                                name="whatsappId"
                                                error={
                                                    touched.whatsappId &&
                                                    Boolean(errors.whatsappId)
                                                }
                                                disabled={!campaignEditable}
                                            >
                                                <MenuItem value="">
                                                    Nenhuma
                                                </MenuItem>
                                                {whatsapps &&
                                                    whatsapps.map(
                                                        (whatsapp) => (
                                                            <MenuItem
                                                                key={
                                                                    whatsapp.id
                                                                }
                                                                value={
                                                                    whatsapp.id
                                                                }
                                                            >
                                                                {whatsapp.name}
                                                            </MenuItem>
                                                        ),
                                                    )}
                                            </Field>
                                        </FormControl>
                                    </Grid2>
                                    <Grid2
                                        size={{
                                            xs: 12,
                                            md: 4,
                                        }}
                                        item
                                    >
                                        <Field
                                            as={TextField}
                                            label={i18n.t(
                                                "campaigns.dialog.form.scheduledAt",
                                            )}
                                            name="scheduledAt"
                                            error={
                                                touched.scheduledAt &&
                                                Boolean(errors.scheduledAt)
                                            }
                                            helperText={
                                                touched.scheduledAt &&
                                                errors.scheduledAt
                                            }
                                            variant="outlined"
                                            margin="dense"
                                            type="datetime-local"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            className={classes.textField}
                                            disabled={!campaignEditable}
                                        />
                                    </Grid2>
                                    <Grid2
                                        size={{
                                            xs: 12,
                                        }}
                                        item
                                    >
                                        <Tabs
                                            value={messageTab}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={(e, v) =>
                                                setMessageTab(v)
                                            }
                                            variant="fullWidth"
                                            centered
                                            style={{
                                                background: "#f2f2f2",
                                                border: "1px solid #e6e6e6",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Tab label="Msg. 1" index={0} />
                                            <Tab label="Msg. 2" index={1} />
                                            <Tab label="Msg. 3" index={2} />
                                            <Tab label="Msg. 4" index={3} />
                                            <Tab label="Msg. 5" index={4} />
                                        </Tabs>
                                        <Box
                                            style={{
                                                paddingTop: 20,
                                                border: "none",
                                            }}
                                        >
                                            {messageTab === 0 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid2
                                                            spacing={2}
                                                            container
                                                        >
                                                            <Grid2
                                                                item
                                                                size={{
                                                                    xs: 12,
                                                                    md: 8,
                                                                }}
                                                            >
                                                                <>
                                                                    {renderMessageField(
                                                                        "message1",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 4,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage1",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                        </Grid2>
                                                    ) : (
                                                        <>
                                                            {renderMessageField(
                                                                "message1",
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {messageTab === 1 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid2
                                                            spacing={2}
                                                            container
                                                        >
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 8,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderMessageField(
                                                                        "message2",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 4,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage2",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                        </Grid2>
                                                    ) : (
                                                        <>
                                                            {renderMessageField(
                                                                "message2",
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {messageTab === 2 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid2
                                                            spacing={2}
                                                            container
                                                        >
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 8,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderMessageField(
                                                                        "message3",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 4,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage3",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                        </Grid2>
                                                    ) : (
                                                        <>
                                                            {renderMessageField(
                                                                "message3",
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {messageTab === 3 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid2
                                                            spacing={2}
                                                            container
                                                        >
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 8,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderMessageField(
                                                                        "message4",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 4,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage4",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                        </Grid2>
                                                    ) : (
                                                        <>
                                                            {renderMessageField(
                                                                "message4",
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {messageTab === 4 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid2
                                                            spacing={2}
                                                            container
                                                        >
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 8,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderMessageField(
                                                                        "message5",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                            <Grid2
                                                                size={{
                                                                    xs: 12,
                                                                    md: 4,
                                                                }}
                                                                item
                                                            >
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage5",
                                                                    )}
                                                                </>
                                                            </Grid2>
                                                        </Grid2>
                                                    ) : (
                                                        <>
                                                            {renderMessageField(
                                                                "message5",
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </Grid2>
                                    {(campaign.mediaPath || attachment) && (
                                        <Grid2
                                            size={{
                                                xs: 12,
                                            }}
                                            item
                                        >
                                            <Button
                                                startIcon={<AttachFileIcon />}
                                            >
                                                {attachment != null
                                                    ? attachment.name
                                                    : campaign.mediaName}
                                            </Button>
                                            {campaignEditable && (
                                                <IconButton
                                                    onClick={() =>
                                                        setConfirmationOpen(
                                                            true,
                                                        )
                                                    }
                                                    color="secondary"
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            )}
                                        </Grid2>
                                    )}
                                </Grid2>
                            </DialogContent>
                            <DialogActions>
                                {campaign.status === "CANCELADA" && (
                                    <Button
                                        color="primary"
                                        onClick={() => restartCampaign()}
                                        variant="outlined"
                                    >
                                        {i18n.t(
                                            "campaigns.dialog.buttons.restart",
                                        )}
                                    </Button>
                                )}
                                {campaign.status === "EM_ANDAMENTO" && (
                                    <Button
                                        color="primary"
                                        onClick={() => cancelCampaign()}
                                        variant="outlined"
                                    >
                                        {i18n.t(
                                            "campaigns.dialog.buttons.cancel",
                                        )}
                                    </Button>
                                )}
                                {!attachment &&
                                    !campaign.mediaPath &&
                                    campaignEditable && (
                                        <Button
                                            color="primary"
                                            onClick={() =>
                                                attachmentFile.current.click()
                                            }
                                            disabled={isSubmitting}
                                            variant="outlined"
                                        >
                                            {i18n.t(
                                                "campaigns.dialog.buttons.attach",
                                            )}
                                        </Button>
                                    )}
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("campaigns.dialog.buttons.close")}
                                </Button>
                                {(campaignEditable ||
                                    campaign.status === "CANCELADA") && (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {campaignId
                                            ? `${i18n.t(
                                                  "campaigns.dialog.buttons.edit",
                                              )}`
                                            : `${i18n.t(
                                                  "campaigns.dialog.buttons.add",
                                              )}`}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default CampaignModal
