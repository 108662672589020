import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"

import { Avatar, Chip, Grid2, Paper, Typography } from "@mui/material"

const useStyles = makeStyles(() => ({
    tagContainer: {
        display: "flex",
        gap: "0.3em",
        flexWrap: "wrap",
    },
    justifyBetween: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "0.5em",
        marginBottom: "0.5em",
    },
    readMoreButton: {
        background: "none",
        border: "none",
        outline: "none",
        cursor: "pointer",
        color: "#007aff",
        paddingLeft: 0,
        marginTop: "0.5em",
    },
}))

export default function KanbanCardDescription({ title, ticket, onCardClick }) {
    const classes = useStyles()
    const [isShortened, setIsShortened] = useState(true)
    const [text, setText] = useState(
        ticket.lastMessage?.length > 100
            ? `${ticket.lastMessage?.slice(0, 100)}...`
            : ticket.lastMessage,
    )

    useEffect(() => {
        if (isShortened && ticket?.lastMessage.length > 100) {
            setText(`${ticket?.lastMessage.slice(0, 100)}...`)
        } else {
            setText(ticket?.lastMessage)
        }
    }, [isShortened])

    return (
        <Grid2>
            <Paper
                onClick={onCardClick}
                sx={{
                    padding: 2,
                    width: "297px",
                    marginBottom: "8px",
                }}
            >
                <Grid2
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={2}
                    sx={{ marginBottom: 1 }}
                >
                    <Avatar
                        src={ticket.contact.profilePicUrl}
                        alt="contact_image"
                        style={{ width: 32, height: 32 }}
                    />
                    <Grid2 display="flex" flexDirection="column">
                        <Typography variant="h6">{title}</Typography>
                        <Typography variant="body1">
                            <small>{ticket.contact.number}</small>
                        </Typography>
                    </Grid2>
                </Grid2>

                <Typography variant="body2">{text}</Typography>

                <Typography variant="caption">
                    {ticket?.lastMessage.length > 100 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsShortened(!isShortened)
                            }}
                            className={classes.readMoreButton}
                            type="button"
                        >
                            {isShortened ? "Ler mais" : "Ler menos"}
                        </button>
                    )}
                </Typography>

                <div className={classes.justifyBetween}>
                    {ticket.product && (
                        <b
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                        >
                            {ticket.product}
                        </b>
                    )}
                    {ticket.value && (
                        <span>
                            {Number(ticket.value)?.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })}
                        </span>
                    )}
                </div>
                <Grid2 display="flex" justifyContent="space-between">
                    <Grid2 display="flex" gap={1} flexWrap="wrap">
                        {ticket.tags?.map((tag) => {
                            if (tag.kanban === 0) {
                                return (
                                    <Chip
                                        key={tag.id}
                                        variant="outlined"
                                        style={{
                                            backgroundColor:
                                                tag.color || "#eee",
                                            textShadow: "1px 1px 1px #000",
                                            color: "white",
                                        }}
                                        label={tag.name}
                                        size="small"
                                    />
                                )
                            }
                        })}
                    </Grid2>
                </Grid2>
            </Paper>
        </Grid2>
    )
}
