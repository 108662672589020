import React, { useState, useEffect, createContext } from "react"
import { useHistory } from "react-router-dom"

const TicketsContext = createContext()

const TicketsContextProvider = ({ children }) => {
    const [currentTicket, setCurrentTicket] = useState({ id: null, code: null })
    const history = useHistory()

    useEffect(() => {
        if (currentTicket.id !== null) {
            history.push(`/tickets/${currentTicket.uuid}`)
        }
    }, [currentTicket])

    return (
        <TicketsContext.Provider value={{ currentTicket, setCurrentTicket }}>
            {children}
        </TicketsContext.Provider>
    )
}

export { TicketsContext, TicketsContextProvider }
