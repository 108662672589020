import React, { useState, useContext } from "react"

import MenuItem from "@mui/material/MenuItem"

import { i18n } from "../../translate/i18n"
import api from "../../services/api"
import ConfirmationModal from "../ConfirmationModal"
import { Menu } from "@mui/material"
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext"
import toastError from "../../errors/toastError"
import { toast } from "react-toastify"

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
    const { setReplyingMessage } = useContext(ReplyMessageContext)
    const [confirmationOpen, setConfirmationOpen] = useState(false)

    const handleDeleteMessage = async () => {
        try {
            await api.delete(`/messages/${message.id}`)
        } catch (err) {
            toastError(err)
        }
    }

    const hanldeReplyMessage = () => {
        setReplyingMessage(message)
        handleClose()
    }

    const handleOpenConfirmationModal = () => {
        setConfirmationOpen(true)
        handleClose()
    }

    const handleUploadAudio = async (blob) => {
        const audio = await api.post("/audios")
        const formData = new FormData()
        const filename = `${Date.now()}.mp3`
        formData.append("file", blob, filename)
        formData.append("body", filename)
        formData.append("fromMe", true)
        await api.post(`/audios/${audio.data.id}/media-upload`, formData)
        toast.success("Áudio gravado com sucesso")
        handleClose()
    }

    const handleFetchAudio = async () => {
        fetch(message.mediaUrl)
            .then((res) => res.blob())
            .then((blob) => {
                handleUploadAudio(blob)
            })
    }

    return (
        <>
            <ConfirmationModal
                title={i18n.t("messageOptionsMenu.confirmationModal.title")}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={handleDeleteMessage}
            >
                {i18n.t("messageOptionsMenu.confirmationModal.message")}
            </ConfirmationModal>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleClose}
            >
                {message.fromMe && (
                    <MenuItem onClick={handleOpenConfirmationModal}>
                        {i18n.t("messageOptionsMenu.delete")}
                    </MenuItem>
                )}
                <MenuItem onClick={hanldeReplyMessage}>
                    {i18n.t("messageOptionsMenu.reply")}
                </MenuItem>
                {message.mediaType === "audio" && (
                    <MenuItem onClick={handleFetchAudio}>
                        Salvar na biblioteca
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default MessageOptionsMenu
