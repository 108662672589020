import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"

import { toast } from "react-toastify"

import InputAdornment from "@mui/material/InputAdornment"
import ButtonWithSpinner from "../ButtonWithSpinner"

import { Grid2 } from "@mui/material"
import api from "../../services/api"

const ProductsSchema = Yup.object().shape({
    product: Yup.string().min(2, "Too Short!").required("Required"),
    value: Yup.string().min(2, "Too Short!").required("Required"),
})

export function ContactProducts({ ticket }) {
    const [product, setProduct] = useState({ product: "", value: "" })
    const [loading, setLoading] = useState(false)

    const handleChange = (key, value) => {
        setProduct({ ...product, [key]: value })
    }

    useEffect(() => {
        if (ticket.product || ticket.value) {
            setProduct(ticket)
        }
    }, [ticket])

    const handleSave = async (values) => {
        setLoading(true)
        try {
            const payload = {
                product: values.product,
                value: parseFloat(values.value),
            }

            await api.put(`/tickets/${ticket.id}`, { ...payload })
            toast.success("Observação adicionada com sucesso!")
        } catch (e) {
            toast.error(e)
        }
        setLoading(false)
    }

    return (
        <>
            <Formik
                initialValues={product}
                enableReinitialize={true}
                validationSchema={ProductsSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSave(values)
                        actions.setSubmitting(false)
                    }, 400)
                }}
            >
                {({ setErrors }) => (
                    <Form>
                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 12 }} item spacing={4}>
                                <Field
                                    id="outlined-basic"
                                    as={TextField}
                                    label="Produto"
                                    variant="outlined"
                                    name="product"
                                    // error={touched.note && Boolean(errors.note)}
                                    // helperText={touched.note && errors.note}
                                    fullWidth
                                    onChange={(e) =>
                                        handleChange("product", e.target.value)
                                    }
                                    style={{
                                        marginBottom: "20px",
                                    }}
                                />
                                <Field
                                    id="outlined-basic"
                                    as={TextField}
                                    label="Valor"
                                    variant="outlined"
                                    name="value"
                                    fullWidth
                                    type="number"
                                    // error={touched.note && Boolean(errors.note)}
                                    // helperText={touched.note && errors.note}
                                    onChange={(e) =>
                                        handleChange("value", e.target.value)
                                    }
                                    style={{
                                        marginBottom: "20px",
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                    labelWidth={60}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }} item>
                                <Grid2 container spacing={2}>
                                    <Grid2 size={{ xs: 6 }} item>
                                        <Button
                                            onClick={() => {
                                                setProduct({
                                                    value: "",
                                                    product: "",
                                                })
                                                setErrors({})
                                            }}
                                            color="primary"
                                            variant="outlined"
                                            fullWidth
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid2>
                                    <Grid2 size={{ xs: 6 }} item>
                                        <ButtonWithSpinner
                                            loading={loading}
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            autoFocus
                                            fullWidth
                                        >
                                            Salvar
                                        </ButtonWithSpinner>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Form>
                )}
            </Formik>
        </>
    )
}
