import React, { useEffect, useState } from "react"

import Grid2 from "@mui/material/Grid2"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import FormHelperText from "@mui/material/FormHelperText"
import TextField from "@mui/material/TextField"

import useSettings from "../../hooks/useSettings"
import { toast } from "react-toastify"
import { makeStyles } from "@mui/styles"
import { grey, blue } from "@mui/material/colors"
import { Tabs, Tab } from "@mui/material"

//import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    tab: {
        backgroundColor: theme.palette.mode === "light" ? "#f2f2f2" : "#7f7f7f",
        borderRadius: 4,
        width: "100%",
        "& .MuiTabs-flexContainer": {
            justifyContent: "center",
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
        width: "100%",
    },
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: "18px",
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: "14px",
    },
    alignRight: {
        textAlign: "right",
    },
    fullWidth: {
        width: "100%",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },
}))

export default function Options(props) {
    const { settings, scheduleTypeChanged } = props
    const classes = useStyles()
    const [userRating, setUserRating] = useState("disabled")
    const [scheduleType, setScheduleType] = useState("disabled")
    const [callType, setCallType] = useState("enabled")
    const [chatbotType, setChatbotType] = useState("")
    const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled")
    const [outsideMessageType, setOutsideMessageType] = useState("disabled")
    const [loadingUserRating, setLoadingUserRating] = useState(false)
    const [loadingScheduleType, setLoadingScheduleType] = useState(false)
    const [loadingCallType, setLoadingCallType] = useState(false)
    const [loadingChatbotType, setLoadingChatbotType] = useState(false)

    const [loadingOutsideMessageType, setLoadingOutsideMessageType] =
        useState(false)
    // const [loadingOutsideQueueType, setLoadingOutsideQueueType] =
    //     useState(false)

    const [ipixcType, setIpIxcType] = useState("")
    const [loadingIpIxcType, setLoadingIpIxcType] = useState(false)
    const [tokenixcType, setTokenIxcType] = useState("")
    const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false)

    const [facebookAppId, setFacebookAppId] = useState("")
    const [facebookAppSecret, setFacebookAppSecret] = useState("")
    const [facebookVerifyToken, setFacebookVerifyToken] = useState("")

    const [loadingfacebookid, setLoadingfacebookid] = useState(false)
    const [loadingfacebooksecret, setLoadingfacebooksecret] = useState(false)
    const [loadingfacebooktoken, setLoadingfacebooktoken] = useState(false)

    const [ipmkauthType, setIpMkauthType] = useState("")
    const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false)
    const [clientidmkauthType, setClientIdMkauthType] = useState("")
    const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] =
        useState(false)
    const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("")
    const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] =
        useState(false)

    const [asaasType, setAsaasType] = useState("")
    const [loadingAsaasType, setLoadingAsaasType] = useState(false)

    const { update } = useSettings()

    useEffect(() => {
        if (Array.isArray(settings) && settings.length) {
            const userRating = settings.find((s) => s.key === "userRating")
            if (userRating) {
                setUserRating(userRating.value)
            }
            const scheduleType = settings.find((s) => s.key === "scheduleType")
            if (scheduleType) {
                setScheduleType(scheduleType.value)
            }
            const callType = settings.find((s) => s.key === "call")
            if (callType) {
                setCallType(callType.value)
            }

            const chatbotType = settings.find((s) => s.key === "chatBotType")
            if (chatbotType) {
                setChatbotType(chatbotType.value)
            }

            const facebookAppIdType = settings.find(
                (s) => s.key === "facebookAppId",
            )
            if (facebookAppIdType) {
                setFacebookAppId(facebookAppIdType.value)
            }

            const facebookAppSecretType = settings.find(
                (s) => s.key === "facebookAppSecret",
            )
            if (facebookAppSecretType) {
                setFacebookAppSecret(facebookAppSecretType.value)
            }

            const facebookVerifyTokenType = settings.find(
                (s) => s.key === "facebookVerifyToken",
            )
            if (facebookVerifyTokenType) {
                setFacebookVerifyToken(facebookVerifyTokenType.value)
            }

            const ipixcType = settings.find((s) => s.key === "ipixc")
            if (ipixcType) {
                setIpIxcType(ipixcType.value)
            }

            const tokenixcType = settings.find((s) => s.key === "tokenixc")
            if (tokenixcType) {
                setTokenIxcType(tokenixcType.value)
            }

            const ipmkauthType = settings.find((s) => s.key === "ipmkauth")
            if (ipmkauthType) {
                setIpMkauthType(ipmkauthType.value)
            }

            const clientidmkauthType = settings.find(
                (s) => s.key === "clientidmkauth",
            )
            if (clientidmkauthType) {
                setClientIdMkauthType(clientidmkauthType.value)
            }

            const clientsecretmkauthType = settings.find(
                (s) => s.key === "clientsecretmkauth",
            )
            if (clientsecretmkauthType) {
                setClientSecrectMkauthType(clientsecretmkauthType.value)
            }

            const asaasType = settings.find((s) => s.key === "asaas")
            if (asaasType) {
                setAsaasType(asaasType.value)
            }

            const outsideMessageType = settings.find(
                (s) => s.key === "outsidemessage",
            )
            if (outsideMessageType) {
                setOutsideMessageType(outsideMessageType.value)
            }
        }
    }, [settings])

    async function handleChangeUserRating(value) {
        setUserRating(value)
        setLoadingUserRating(true)
        await update({
            key: "userRating",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingUserRating(false)
    }

    async function handleScheduleType(value) {
        setScheduleType(value)
        setLoadingScheduleType(true)
        await update({
            key: "scheduleType",
            value,
        })
        //toast.success("Oraçãpeo atualizada com sucesso.");
        toast.success("Operação atualizada com sucesso.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "light",
        })
        setLoadingScheduleType(false)
        if (typeof scheduleTypeChanged === "function") {
            scheduleTypeChanged(value)
        }
    }

    async function handleCallType(value) {
        setCallType(value)
        setLoadingCallType(true)
        await update({
            key: "call",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingCallType(false)
    }

    async function handleChatbotType(value) {
        setChatbotType(value)
        setLoadingChatbotType(true)
        await update({
            key: "chatBotType",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingChatbotType(false)
    }

    async function handleGroupType(value) {
        setCheckMsgIsGroupType(value)
        await update({
            key: "CheckMsgIsGroup",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
    }

    async function handleChangeIPIxc(value) {
        setIpIxcType(value)
        setLoadingIpIxcType(true)
        await update({
            key: "ipixc",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingIpIxcType(false)
    }

    async function handleChangeTokenIxc(value) {
        setTokenIxcType(value)
        setLoadingTokenIxcType(true)
        await update({
            key: "tokenixc",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingTokenIxcType(false)
    }

    async function handleChangeIpMkauth(value) {
        setIpMkauthType(value)
        setLoadingIpMkauthType(true)
        await update({
            key: "ipmkauth",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingIpMkauthType(false)
    }

    // FACEBOOK

    async function handleChangeFacebookAppId(value) {
        setFacebookAppId(value)
        setLoadingfacebookid(true)
        await update({
            key: "facebookAppId",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingfacebookid(false)
    }

    async function handleChangeFacebookAppSecret(value) {
        setFacebookAppSecret(value)
        setLoadingfacebooksecret(true)
        await update({
            key: "facebookAppSecret",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingfacebooksecret(false)
    }

    async function handleChangeFacebookAppToken(value) {
        setFacebookVerifyToken(value)
        setLoadingfacebooktoken(true)
        await update({
            key: "facebookVerifyToken",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingfacebooktoken(false)
    }

    // FACEBOOK

    async function handleOutsideMessageType(value) {
        setOutsideMessageType(value)
        setLoadingOutsideMessageType(true)
        await update({
            key: "outsidemessage",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingOutsideMessageType(false)
    }

    async function handleChangeClientIdMkauth(value) {
        setClientIdMkauthType(value)
        setLoadingClientIdMkauthType(true)
        await update({
            key: "clientidmkauth",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingClientIdMkauthType(false)
    }

    async function handleChangeClientSecrectMkauth(value) {
        setClientSecrectMkauthType(value)
        setLoadingClientSecrectMkauthType(true)
        await update({
            key: "clientsecretmkauth",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingClientSecrectMkauthType(false)
    }

    async function handleChangeAsaas(value) {
        setAsaasType(value)
        setLoadingAsaasType(true)
        await update({
            key: "asaas",
            value,
        })
        toast.success("Operação atualizada com sucesso.")
        setLoadingAsaasType(false)
    }
    return (
        <>
            <Grid2 spacing={3} container>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="ratings-label">Avaliações</InputLabel>
                        <Select
                            labelId="ratings-label"
                            value={userRating}
                            onChange={async (e) => {
                                handleChangeUserRating(e.target.value)
                            }}
                        >
                            <MenuItem value={"disabled"}>
                                Desabilitadas
                            </MenuItem>
                            <MenuItem value={"enabled"}>Habilitadas</MenuItem>
                        </Select>
                        <FormHelperText>
                            {loadingUserRating && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="schedule-type-label">
                            Gerenciamento de Expediente
                        </InputLabel>
                        <Select
                            labelId="schedule-type-label"
                            value={scheduleType}
                            onChange={async (e) => {
                                handleScheduleType(e.target.value)
                            }}
                        >
                            <MenuItem value={"disabled"}>Desabilitado</MenuItem>
                            <MenuItem value={"queue"}>
                                Gerenciamento Por Fila
                            </MenuItem>
                            <MenuItem value={"company"}>
                                Gerenciamento Por Empresa
                            </MenuItem>
                        </Select>
                        <FormHelperText>
                            {loadingScheduleType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="group-type-label">
                            Ignorar Mensagens de Grupos
                        </InputLabel>
                        <Select
                            labelId="group-type-label"
                            value={CheckMsgIsGroup}
                            onChange={async (e) => {
                                handleGroupType(e.target.value)
                            }}
                        >
                            <MenuItem value={"disabled"}>Desativado</MenuItem>
                            <MenuItem value={"enabled"}>Ativado</MenuItem>
                        </Select>
                        <FormHelperText>
                            {loadingScheduleType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="call-type-label">
                            Aceitar Chamada
                        </InputLabel>
                        <Select
                            labelId="call-type-label"
                            value={callType}
                            onChange={async (e) => {
                                handleCallType(e.target.value)
                            }}
                        >
                            <MenuItem value={"disabled"}>Não Aceitar</MenuItem>
                            <MenuItem value={"enabled"}>Aceitar</MenuItem>
                        </Select>
                        <FormHelperText>
                            {loadingCallType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="chatbot-type-label">
                            Tipo Chatbot
                        </InputLabel>
                        <Select
                            labelId="chatbot-type-label"
                            value={chatbotType}
                            onChange={async (e) => {
                                handleChatbotType(e.target.value)
                            }}
                        >
                            <MenuItem value={"text"}>Texto</MenuItem>
                            {/*<MenuItem value={"button"}>Botão</MenuItem>
              <MenuItem value={"list"}>Lista</MenuItem>*/}
                        </Select>
                        <FormHelperText>
                            {loadingChatbotType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>

                <Grid2
                    size={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="outsidemessage-type-label">
                            Abrir Tickets Fora do Expediente?
                        </InputLabel>
                        <Select
                            labelId="outsidemessage-type-label"
                            value={outsideMessageType}
                            onChange={async (e) => {
                                handleOutsideMessageType(e.target.value)
                            }}
                        >
                            <MenuItem value={"disabled"}>Não</MenuItem>
                            <MenuItem value={"enabled"}>Sim</MenuItem>
                        </Select>
                        <FormHelperText>
                            {loadingOutsideMessageType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
            </Grid2>

            <Grid2 spacing={3} container style={{ marginBottom: 10 }}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                >
                    <Tab label="Integrações" />
                </Tabs>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="facebookAppId"
                            name="facebookAppId"
                            margin="dense"
                            label="APP ID META"
                            variant="outlined"
                            value={facebookAppId}
                            onChange={async (e) => {
                                handleChangeFacebookAppId(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingfacebookid && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="facebookAppSecret"
                            name="facebookAppSecret"
                            margin="dense"
                            label="APP Secret META"
                            variant="outlined"
                            value={facebookAppSecret}
                            onChange={async (e) => {
                                handleChangeFacebookAppSecret(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingfacebooksecret && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="facebookVerifyToken"
                            name="facebookVerifyToken"
                            margin="dense"
                            label="Token de verificação META"
                            variant="outlined"
                            value={facebookVerifyToken}
                            onChange={async (e) => {
                                handleChangeFacebookAppToken(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingfacebooktoken && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
            </Grid2>

            {/*-----------------IXC-----------------*/}
            <Grid2 spacing={3} container style={{ marginBottom: 10 }}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                >
                    <Tab label="IXC" />
                </Tabs>
                <Grid2 size={{ xs: 12, sm: 6, md: 6 }} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="ipixc"
                            name="ipixc"
                            margin="dense"
                            label="IP do IXC"
                            variant="outlined"
                            value={ipixcType}
                            onChange={async (e) => {
                                handleChangeIPIxc(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingIpIxcType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 6 }} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="tokenixc"
                            name="tokenixc"
                            margin="dense"
                            label="Token do IXC"
                            variant="outlined"
                            value={tokenixcType}
                            onChange={async (e) => {
                                handleChangeTokenIxc(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingTokenIxcType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
            </Grid2>

            {/*-----------------MK-AUTH-----------------*/}
            <Grid2 spacing={3} container style={{ marginBottom: 10 }}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                >
                    <Tab label="MK-AUTH" />
                </Tabs>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="ipmkauth"
                            name="ipmkauth"
                            margin="dense"
                            label="Ip Mk-Auth"
                            variant="outlined"
                            value={ipmkauthType}
                            onChange={async (e) => {
                                handleChangeIpMkauth(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingIpMkauthType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="clientidmkauth"
                            name="clientidmkauth"
                            margin="dense"
                            label="Client Id"
                            variant="outlined"
                            value={clientidmkauthType}
                            onChange={async (e) => {
                                handleChangeClientIdMkauth(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingClientIdMkauthType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 4,
                    }}
                    item
                >
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="clientsecretmkauth"
                            name="clientsecretmkauth"
                            margin="dense"
                            label="Client Secret"
                            variant="outlined"
                            value={clientsecretmkauthType}
                            onChange={async (e) => {
                                handleChangeClientSecrectMkauth(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingClientSecrectMkauthType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
            </Grid2>

            {/*-----------------ASAAS-----------------*/}
            <Grid2 spacing={3} container style={{ marginBottom: 10 }}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                >
                    <Tab label="ASAAS" />
                </Tabs>
                <Grid2 size={{ xs: 12, sm: 12, md: 12 }} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="asaas"
                            name="asaas"
                            margin="dense"
                            label="Token Asaas"
                            variant="outlined"
                            value={asaasType}
                            onChange={async (e) => {
                                handleChangeAsaas(e.target.value)
                            }}
                        ></TextField>
                        <FormHelperText>
                            {loadingAsaasType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid2>
            </Grid2>
        </>
    )
}
