import React, { useEffect, useRef, useState } from "react"
import {
    Chip,
    Paper,
    TextField,
    MenuItem,
    FormControl,
    Select,
    Grid2,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { isArray, isString } from "lodash"
import toastError from "../../errors/toastError"
import { toast } from "react-toastify"
import api from "../../services/api"
import { i18n } from "../../translate/i18n"

export function TagsContainer({ ticket }) {
    const [tags, setTags] = useState([])
    const [kanban, setKanban] = useState([])
    const [selecteds, setSelecteds] = useState([])
    const [selectedKanban, setSelectedKanban] = useState("lane0")
    const isMounted = useRef(true)

    const handleSelect = async (event) => {
        const { value } = event.target

        try {
            await api.delete(`/ticket-tags/${ticket.id}`)
            if (value !== "lane0") {
                await api.put(`/ticket-tags/${ticket.id}/${value}`)
            }
            toast.success("Kanban atualizado com sucesso")
            setSelectedKanban(value)
        } catch (error) {
            toastError(error)
        }
    }

    const loadTags = async () => {
        try {
            const [tagsResponse, kanbanResponse] = await Promise.all([
                api.get(`/tags/list`),
                api.get(`/tags/kanban`),
            ])
            setTags(tagsResponse.data)

            const kanbanRes = kanbanResponse.data.lista

            kanbanRes.unshift({
                id: "lane0",
                name: `${i18n.t("tagsKanban.laneDefault")}`,
            })

            setKanban(kanbanRes)
        } catch (error) {
            toastError(error)
        }
    }

    const createTag = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags`, data)
            return responseData
        } catch (error) {
            toastError(error)
        }
    }

    const syncTags = async (data) => {
        try {
            await api.post(`/tags/sync`, data)
        } catch (error) {
            toastError(error)
        }
    }

    const onChange = async (value, reason) => {
        let optionsChanged = []

        if (reason === "create-option") {
            if (isArray(value)) {
                for (const item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item })
                        optionsChanged.push(newTag)
                    } else {
                        optionsChanged.push(item)
                    }
                }
            }
            await loadTags()
        } else {
            optionsChanged = value
        }

        setSelecteds(optionsChanged)
        await syncTags({ ticketId: ticket.id, tags: optionsChanged })
    }

    useEffect(() => {
        if (isMounted.current) {
            loadTags()
        }
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (Array.isArray(ticket.tags)) {
            const selectedTagIds = ticket.tags.map((tag) => tag.id)
            const selectedTagList = tags.filter((tag) =>
                selectedTagIds.includes(tag.id),
            )
            setSelecteds(selectedTagList)

            const kanbanTag = kanban.find((item) =>
                selectedTagIds.includes(item.id),
            )
            if (kanbanTag) {
                setSelectedKanban(kanbanTag.id)
            }
        } else {
            setSelecteds([])
        }
    }, [ticket, tags, kanban])

    return (
        <Paper>
            <Grid2 display="flex">
                <Grid2 flexBasis="80%">
                    <Autocomplete
                        multiple
                        size="small"
                        options={tags}
                        value={selecteds}
                        freeSolo
                        onChange={(e, value, reason) => onChange(value, reason)}
                        getOptionLabel={(option) =>
                            option.name ? option.name : ""
                        }
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={option.id || index}
                                    variant="outlined"
                                    style={{
                                        backgroundColor: option.color || "#eee",
                                        color: "white",
                                        textShadow: "1px 1px 1px #000",
                                    }}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                    size="small"
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Tags"
                            />
                        )}
                        PaperComponent={({ children }) => (
                            <Paper style={{ width: 400, marginLeft: 12 }}>
                                {children}
                            </Paper>
                        )}
                    />
                </Grid2>

                <Grid2 flexBasis="20%">
                    <FormControl size="small" fullWidth>
                        <Select
                            value={selectedKanban}
                            onChange={handleSelect}
                            fullWidth
                            variant="outlined"
                            placeholder="Kanban"
                        >
                            {kanban.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid2>
            </Grid2>
        </Paper>
    )
}
