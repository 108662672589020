import React, { useState, useEffect } from "react"
import qs from "query-string"

import * as Yup from "yup"
import { useHistory } from "react-router-dom"
import { Link as RouterLink } from "react-router-dom"
import { toast } from "react-toastify"
import { Formik, Form, Field } from "formik"
import {
    Button,
    CssBaseline,
    TextField,
    Grid2,
    Typography,
    Container,
    Link,
    Switch,
    Tooltip,
} from "@mui/material"
import usePlans from "../../hooks/usePlans"
import Box from "@mui/material/Box"
import InfoIcon from "@mui/icons-material/Info"
import { makeStyles, useTheme } from "@mui/styles"
import { i18n } from "../../translate/i18n"
import api, { openApi } from "../../services/api"
import toastError from "../../errors/toastError"
import moment from "moment"

import config from "../../config/config.json"

import InputMask from "react-input-mask"

// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        background: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        //backgroundColor: `rgba(${theme.palette.background.paper}, 0.8)`,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    powered: {
        color: "white",
    },
}))

const UserSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
    email: Yup.string().email("Invalid email").required("Required"),
})

const SignUp = () => {
    const theme = useTheme()
    // const params = useParams()
    const classes = useStyles()
    const history = useHistory()

    const params = qs.parse(window.location.search)

    const logo = config.system.logoLoginScreen[theme.palette.mode]

    const initialState = {
        name: "",
        email: "",
        phone: "",
        password: "",
        planId: "",
    }

    const [plans, setPlans] = useState([])
    const [subsidiaries, setSubsidiaries] = useState([])
    const [tipoPessoa, setTipoPessoa] = useState("pf")
    const { list: listPlans } = usePlans()

    const [user] = useState(initialState)
    const dueDate = moment().add(3, "day").format()
    const handleSignUp = async (values) => {
        values.phone = values.phone.replace(/\D/g, "")
        Object.assign(values, { recurrence: "MENSAL" })
        Object.assign(values, { dueDate: dueDate })
        Object.assign(values, { status: "t" })
        Object.assign(values, { campaignsEnabled: true })
        Object.assign(values, { planId: plans[0]?.id })
        Object.assign(values, { document: values.document.replace(/\D/g, "") })

        if (params.subsidiary_unique_id && subsidiaries.length) {
            const subsidiary = subsidiaries.filter(
                (subs) => subs.uuid === params.subsidiary_unique_id,
            )[0]
            Object.assign(values, { subsidiaryId: subsidiary.id })
        }

        try {
            await openApi.post("/companies/cadastro", values)
            toast.success(i18n.t("signup.toasts.success"))
            history.push("/login")
        } catch (err) {
            console.log(err)
            toastError(err)
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const list = await listPlans()
                setPlans(list)

                if (params.subsidiary_unique_id) {
                    const subsidiariesList = await api.get("/subsidiaries")
                    setSubsidiaries(subsidiariesList.data)
                    console.log("SUBSIDIARIES: ", subsidiariesList)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])

    const CustomInput = (props) => (
        <InputMask mask="(99) 9 9999-9999" {...props}>
            {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
    )

    const CustomInputDoc = (props) => {
        return (
            <InputMask
                prefix="teste"
                mask={
                    tipoPessoa === "pf"
                        ? "999.999.999-99"
                        : "99.999.999/9999-99"
                }
                {...props}
            >
                {(inputProps) => (
                    <TextField
                        {...inputProps}
                        InputProps={{
                            endAdornment: (
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: 14 }}>
                                            O documento é obrigatório para a
                                            emissão de faturas
                                        </span>
                                    }
                                    placement="right-start"
                                    style={{ cursor: "pointer" }}
                                >
                                    <InfoIcon />
                                </Tooltip>
                            ),
                        }}
                    />
                )}
            </InputMask>
        )
    }

    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <div>
                        <img
                            style={{
                                margin: "0 auto",
                                height: "80px",
                                width: "100%",
                            }}
                            src={logo}
                            alt="Whats"
                        />
                    </div>
                    <Typography
                        style={{ color: "green" }}
                        component="h1"
                        variant="h5"
                    >
                        Teste grátis por 7 dias
                    </Typography>
                    <Typography component="small" variant="caption">
                        Os dados do cartão só serão solicitados após o periodo
                        de testes
                    </Typography>

                    <Formik
                        initialValues={user}
                        enableReinitialize={true}
                        validationSchema={UserSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSignUp(values)
                                actions.setSubmitting(false)
                            }, 400)
                        }}
                    >
                        {({ touched, errors }) => (
                            <Form className={classes.form}>
                                <Grid2 container spacing={2}>
                                    <Grid2 item size={{ xs: 12 }}>
                                        <Field
                                            as={TextField}
                                            autoComplete="name"
                                            name="name"
                                            error={
                                                touched.name &&
                                                Boolean(errors.name)
                                            }
                                            helperText={
                                                touched.name && errors.name
                                            }
                                            variant="outlined"
                                            fullWidth
                                            id="name"
                                            label="Nome*"
                                        />
                                    </Grid2>

                                    <Grid2 item size={{ xs: 12 }}>
                                        <Field
                                            as={CustomInput}
                                            variant="outlined"
                                            fullWidth
                                            id="phone"
                                            label="WhatsApp"
                                            name="phone"
                                            error={
                                                touched.phone &&
                                                Boolean(errors.phone)
                                            }
                                            helperText={
                                                touched.phone && errors.phone
                                            }
                                            autoComplete="phone"
                                            required
                                        />
                                    </Grid2>
                                    <Grid2
                                        item
                                        size={{ xs: 12 }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>Pessoa física</Typography>
                                        <Switch
                                            onChange={(e) => {
                                                if (e.target.value === "pf") {
                                                    setTipoPessoa("pj")
                                                } else {
                                                    setTipoPessoa("pf")
                                                }
                                            }}
                                            value={tipoPessoa}
                                        />
                                        <Typography>Pessoa jurídica</Typography>
                                    </Grid2>
                                    <Grid2 item size={{ xs: 12 }}>
                                        <Field
                                            as={CustomInputDoc}
                                            variant="outlined"
                                            fullWidth
                                            id="document"
                                            label={
                                                tipoPessoa === "pf"
                                                    ? "CPF"
                                                    : "CNPJ"
                                            }
                                            name="document"
                                            error={
                                                touched.document &&
                                                Boolean(errors.document)
                                            }
                                            helperText={
                                                touched.document &&
                                                errors.document
                                            }
                                            autoComplete="document"
                                            required
                                        />
                                    </Grid2>
                                    <Grid2 item size={{ xs: 12 }}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            id="email"
                                            label={i18n.t("signup.form.email")}
                                            name="email"
                                            error={
                                                touched.email &&
                                                Boolean(errors.email)
                                            }
                                            helperText={
                                                touched.email && errors.email
                                            }
                                            autoComplete="email"
                                            required
                                        />
                                    </Grid2>
                                    <Grid2 item size={{ xs: 12 }}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="password"
                                            error={
                                                touched.password &&
                                                Boolean(errors.password)
                                            }
                                            helperText={
                                                touched.password &&
                                                errors.password
                                            }
                                            label={i18n.t(
                                                "signup.form.password",
                                            )}
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            required
                                        />
                                    </Grid2>
                                    {/* <Grid2 item size={{ xs: 12 }}>
									<InputLabel htmlFor="plan-selection">Plano</InputLabel>
									<Field
										as={Select}
										variant="outlined"
										fullWidth
										id="plan-selection"
										label="Plano"
										name="planId"
										required
									>
										{plans?.map((plan, key) => (
											<MenuItem key={key} value={plan?.id}>
												{plan?.name} - Atendentes: {plan?.users} - WhatsApp: {plan?.connections} - Filas: {plan?.queues} - R$ {plan?.value}
											</MenuItem>
										))}
									</Field>
								</Grid2> */}
                                </Grid2>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {i18n.t("signup.buttons.submit")}
                                </Button>
                                <Grid2 container justify="flex-end">
                                    <Grid2 item>
                                        <Link
                                            href="#"
                                            variant="body2"
                                            component={RouterLink}
                                            to="/login"
                                        >
                                            {i18n.t("signup.buttons.login")}
                                        </Link>
                                    </Grid2>
                                </Grid2>
                            </Form>
                        )}
                    </Formik>
                </div>
                <Box mt={5}>{/* <Copyright /> */}</Box>
            </Container>
        </div>
    )
}

export default SignUp
