import React, { useState, useEffect, useReducer, useCallback } from "react"
import { toast } from "react-toastify"

import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import IconButton from "@mui/material/IconButton"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditIcon from "@mui/icons-material/Edit"

import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper"
import Title from "../../components/Title"

import api from "../../services/api"
import { i18n } from "../../translate/i18n"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import AudioModal from "../../components/AudioModal"
import ConfirmationModal from "../../components/ConfirmationModal"
import toastError from "../../errors/toastError"
import moment from "moment"
import EditAudioName from "../../components/AudioModal/EditAudioName"

const reducer = (state, action) => {
    if (action.type === "LOAD_SCHEDULES") {
        const schedules = action.payload
        const newSchedules = []

        schedules.forEach((schedule) => {
            const scheduleIndex = state.findIndex((s) => s.id === schedule.id)
            if (scheduleIndex !== -1) {
                state[scheduleIndex] = schedule
            } else {
                newSchedules.push(schedule)
            }
        })

        return [...state, ...newSchedules]
    }

    if (action.type === "UPDATE_SCHEDULES") {
        const schedule = action.payload
        const scheduleIndex = state.findIndex((s) => s.id === schedule.id)

        if (scheduleIndex !== -1) {
            state[scheduleIndex] = schedule
            return [...state]
        } else {
            return [schedule, ...state]
        }
    }

    if (action.type === "DELETE_SCHEDULE") {
        const scheduleId = action.payload

        const scheduleIndex = state.findIndex((s) => s.id === scheduleId)
        if (scheduleIndex !== -1) {
            state.splice(scheduleIndex, 1)
        }
        return [...state]
    }

    if (action.type === "RESET") {
        return []
    }
}

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}))

const Audios = () => {
    const classes = useStyles()

    const [loading, setLoading] = useState(false)

    const [selectedAudio, setSelectedAudio] = useState(null)
    const [deletingSchedule, setDeletingSchedule] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    const [schedules, dispatch] = useReducer(reducer, [])
    const [audioModalOpen, setAudioModalOpen] = useState(false)

    const [modalEditAudio, setModalEdit] = useState(false)

    const fetchAudios = useCallback(async () => {
        try {
            const { data } = await api.get("/audios")
            dispatch({ type: "LOAD_SCHEDULES", payload: data.audios })
            setLoading(false)
        } catch (err) {
            toastError(err)
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            fetchAudios()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [fetchAudios])

    const handleAudioModal = () => {
        setSelectedAudio(null)
        setAudioModalOpen(true)
    }

    const handleCloseAudioModal = () => {
        setSelectedAudio(null)
        setAudioModalOpen(false)
        fetchAudios()
    }

    const handleDeleteSchedule = async (scheduleId) => {
        try {
            await api.delete(`/audios/${scheduleId}`)
            toast.success(i18n.t("Áudio excluído com sucesso"))
        } catch (err) {
            toastError(err)
        }
        setDeletingSchedule(null)

        dispatch({ type: "RESET" })
        await fetchAudios()
    }

    const getMediaPath = (filename) => {
        return filename
    }

    return (
        <MainContainer>
            <AudioModal open={audioModalOpen} onClose={handleCloseAudioModal} />
            <EditAudioName
                open={modalEditAudio}
                handleClose={() => {
                    setModalEdit(false)
                    fetchAudios()
                }}
                audio={selectedAudio}
            />
            <ConfirmationModal
                title={
                    deletingSchedule &&
                    "Você tem certeza que quer excluir este áudio?"
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteSchedule(deletingSchedule.id)}
            >
                {i18n.t("schedules.confirmationModal.deleteMessage")}
            </ConfirmationModal>

            <MainHeader>
                <Title>Áudios</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAudioModal}
                    >
                        Novo áudio
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Id</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Ouvir</TableCell>
                            <TableCell align="center">Gravado</TableCell>
                            <TableCell align="center">
                                {i18n.t("schedules.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {schedules.map((schedule) => (
                                <TableRow key={schedule.id}>
                                    <TableCell align="center">
                                        {schedule.id}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        title={schedule.body}
                                    >
                                        {schedule.mediaName}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        title={schedule.body}
                                    >
                                        <audio controls>
                                            <source
                                                src={getMediaPath(
                                                    schedule.mediaPath,
                                                )}
                                                type="audio/mpeg"
                                            />
                                        </audio>
                                    </TableCell>
                                    <TableCell align="center">
                                        {moment(schedule.sendAt).format(
                                            "DD/MM/YYYY HH:mm:ss",
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setModalEdit(true)
                                                setSelectedAudio(schedule)
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setConfirmModalOpen(true)
                                                setDeletingSchedule(schedule)
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Audios
