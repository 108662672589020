import { initializeApp } from "firebase/app"

import {
    getAuth,
    signInWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence,
} from "firebase/auth"

import firebaseConfig from "../config/firebase.config.json"

const app = initializeApp(firebaseConfig)

export async function loginFirebaseUser() {
    const email = process.env.REACT_APP_FIREBASE_EMAIL
    const password = process.env.REACT_APP_FIREBASE_PASS

    const auth = getAuth(app)

    if (auth.currentUser) {
        return auth.currentUser
    }

    return setPersistence(auth, browserSessionPersistence).then(() => {
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user
                return user
            })
            .catch(function (login_error) {
                let loginErrorMessage = login_error.message

                throw new Error(loginErrorMessage)
            })
            .catch((error) => {
                // Handle Errors here.

                const errorMessage = error.message

                throw new Error(errorMessage)
            })
    })
}
