import React from "react"

import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    MainHeaderButtonsWrapper: {
        display: "flex",
        flex: "none",
        gap: 5,
        alignItems: "center",
        marginLeft: "auto",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}))

const MainHeaderButtonsWrapper = ({ children }) => {
    const classes = useStyles()

    return <div className={classes.MainHeaderButtonsWrapper}>{children}</div>
}

export default MainHeaderButtonsWrapper
