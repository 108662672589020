import React from "react"
import { useFormikContext } from "formik"
import { Typography, Grid2 } from "@mui/material"
import ShippingDetails from "./ShippingDetails"

export default function ReviewOrder() {
    const { values: formValues } = useFormikContext()
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Resumo da assinatura
            </Typography>
            <Grid2 container spacing={2}>
                <ShippingDetails formValues={formValues} />
            </Grid2>
        </React.Fragment>
    )
}
