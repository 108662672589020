import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "2rem",
    },
    inputContainer: {
        display: "flex",
        width: "100%",
        marginBottom: "1rem",
    },
    input: {
        flexGrow: 1,
        marginRight: "1rem",
    },
    listContainer: {
        width: "100%",
        height: "100%",
        marginTop: "1rem",
        backgroundColor: "#007aff",
        color: "#fafafa",
        borderRadius: "5px",
    },
    list: {
        marginBottom: "5px",
    },
})

const ToDoList = () => {
    const classes = useStyles()

    const [task, setTask] = useState("")
    const [tasks, setTasks] = useState([])
    const [editIndex, setEditIndex] = useState(-1)

    useEffect(() => {
        const savedTasks = localStorage.getItem("tasks")
        if (savedTasks) {
            setTasks(JSON.parse(savedTasks))
        }
    }, [])

    useEffect(() => {
        localStorage.setItem("tasks", JSON.stringify(tasks))
    }, [tasks])

    const handleTaskChange = (event) => {
        setTask(event.target.value)
    }

    const handleAddTask = () => {
        if (!task.trim()) {
            // Impede que o usuário crie uma tarefa sem texto
            return
        }

        const now = new Date()
        if (editIndex >= 0) {
            // Editar tarefa existente
            const newTasks = [...tasks]
            newTasks[editIndex] = {
                text: task,
                updatedAt: now,
                createdAt: newTasks[editIndex].createdAt,
            }
            setTasks(newTasks)
            setTask("")
            setEditIndex(-1)
        } else {
            // Adicionar nova tarefa
            setTasks([...tasks, { text: task, createdAt: now, updatedAt: now }])
            setTask("")
        }
    }

    const handleEditTask = (index) => {
        setTask(tasks[index].text)
        setEditIndex(index)
    }

    const handleDeleteTask = (index) => {
        const newTasks = [...tasks]
        newTasks.splice(index, 1)
        setTasks(newTasks)
    }

    return (
        <div className={classes.root}>
            <div className={classes.inputContainer}>
                <TextField
                    className={classes.input}
                    label="Nova tarefa"
                    value={task}
                    onChange={handleTaskChange}
                    variant="outlined"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddTask}
                >
                    {editIndex >= 0 ? "Salvar" : "Adicionar"}
                </Button>
            </div>
            <div className={classes.listContainer}>
                <List>
                    {tasks.map((task, index) => (
                        <ListItem key={index} className={classes.list}>
                            <ListItemText
                                primary={task.text}
                                secondary={task.updatedAt.toLocaleString()}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    onClick={() => handleEditTask(index)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => handleDeleteTask(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    )
}

export default ToDoList
