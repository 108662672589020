import React, { useState } from "react"
import ColorPicker from "../../components/ColorPicker"

const SelectColor = ({ currentColor, onSelectColor }) => {
    const [open, setOpen] = useState(false)

    const handleCloseColorPicked = (color) => {
        onSelectColor(color)
        setOpen(false)
    }

    return (
        <React.Fragment>
            <span
                style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: currentColor,
                    cursor: "pointer",
                }}
                onClick={() => setOpen(true)}
            />
            <ColorPicker
                open={open}
                handleClose={() => setOpen(false)}
                currentColor={currentColor}
                onChange={(color) => {
                    handleCloseColorPicked(color)
                }}
            />
        </React.Fragment>
    )
}

export default SelectColor
