import React, { useContext, useEffect, useReducer, useState } from "react"
import { Link as RouterLink, useHistory } from "react-router-dom"

import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import Divider from "@mui/material/Divider"
import { Badge, Collapse, List } from "@mui/material"

import WhatsAppIcon from "@mui/icons-material/WhatsApp"

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined"

import CodeRoundedIcon from "@mui/icons-material/CodeRounded"
import EventIcon from "@mui/icons-material/Event"

import EventAvailableIcon from "@mui/icons-material/EventAvailable"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PeopleIcon from "@mui/icons-material/People"
import ListIcon from "@mui/icons-material/ListAlt"

import {
    BlurCircular,
    Dashboard,
    DeviceHubOutlined,
    Mic,
} from "@mui/icons-material"

import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined"

import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined"
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined"
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined"
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import EmailIcon from "@mui/icons-material/Email"
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined"
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined"
import WrapTextOutlinedIcon from "@mui/icons-material/WrapTextOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined"

import { i18n } from "../translate/i18n"
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext"
import { AuthContext } from "../context/Auth/AuthContext"
import { Can } from "../components/Can"
import { socketConnection } from "../services/socket"
import { isArray } from "lodash"
import api from "../services/api"
import toastError from "../errors/toastError"
import { useTheme } from "@mui/styles"

function ListItemLink(props) {
    const { icon, primary, to, className } = props
    const theme = useTheme()

    const renderLink = React.useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to],
    )

    return (
        <ListItem component={renderLink} className={className}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText
                primary={primary}
                sx={{ color: theme.palette.textPrimary }}
            />
        </ListItem>
    )
}

const reducer = (state, action) => {
    if (action.type === "LOAD_CHATS") {
        const chats = action.payload
        const newChats = []

        if (isArray(chats)) {
            chats.forEach((chat) => {
                const chatIndex = state.findIndex((u) => u.id === chat.id)
                if (chatIndex !== -1) {
                    state[chatIndex] = chat
                } else {
                    newChats.push(chat)
                }
            })
        }

        return [...state, ...newChats]
    }

    if (action.type === "UPDATE_CHATS") {
        const chat = action.payload
        const chatIndex = state.findIndex((u) => u.id === chat.id)

        if (chatIndex !== -1) {
            state[chatIndex] = chat
            return [...state]
        } else {
            return [chat, ...state]
        }
    }

    if (action.type === "DELETE_CHAT") {
        const chatId = action.payload

        const chatIndex = state.findIndex((u) => u.id === chatId)
        if (chatIndex !== -1) {
            state.splice(chatIndex, 1)
        }
        return [...state]
    }

    if (action.type === "RESET") {
        return []
    }

    if (action.type === "CHANGE_CHAT") {
        const changedChats = state.map((chat) => {
            if (chat.id === action.payload.chat.id) {
                return action.payload.chat
            }
            return chat
        })
        return changedChats
    }
}

const MainListItems = (props) => {
    const { drawerClose } = props
    const { whatsApps } = useContext(WhatsAppsContext)
    const { user } = useContext(AuthContext)
    const [connectionWarning, setConnectionWarning] = useState(false)
    const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false)
    const [showCampaigns, setShowCampaigns] = useState(false)
    const history = useHistory()

    const [invisible, setInvisible] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [searchParam] = useState("")
    const [chats, dispatch] = useReducer(reducer, [])

    useEffect(() => {
        dispatch({ type: "RESET" })
        setPageNumber(1)
    }, [searchParam])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchChats()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, pageNumber])

    useEffect(() => {
        const companyId = localStorage.getItem("companyId")
        const socket = socketConnection({ companyId })

        socket.on(`company-${companyId}-chat`, (data) => {
            if (data.action === "new-message") {
                dispatch({ type: "CHANGE_CHAT", payload: data })
            }
            if (data.action === "update") {
                dispatch({ type: "CHANGE_CHAT", payload: data })
            }
        })
        return () => {
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        let unreadsCount = 0
        if (chats.length > 0) {
            for (let chat of chats) {
                for (let chatUser of chat.users) {
                    if (chatUser.userId === user.id) {
                        unreadsCount += chatUser.unreads
                    }
                }
            }
        }
        if (unreadsCount > 0) {
            setInvisible(false)
        } else {
            setInvisible(true)
        }
    }, [chats, user.id])

    useEffect(() => {
        if (localStorage.getItem("cshow")) {
            setShowCampaigns(true)
        }
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    )
                })
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true)
                } else {
                    setConnectionWarning(false)
                }
            }
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    }, [whatsApps])

    const fetchChats = async () => {
        try {
            const { data } = await api.get("/chats/", {
                params: { searchParam, pageNumber },
            })
            dispatch({ type: "LOAD_CHATS", payload: data.records })
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <div onClick={drawerClose}>
            <Can
                role={user.profile}
                perform="dashboard:view"
                yes={() => (
                    <ListItemLink
                        to="/"
                        primary="Dashboard"
                        icon={<PieChartOutlinedIcon />}
                    />
                )}
            />

            <ListItemLink
                to="/tickets"
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<WhatsAppIcon />}
            />

            <ListItemLink
                to="/todolist"
                primary={i18n.t("mainDrawer.listItems.todolist")}
                icon={<EventIcon />}
            />

            <ListItemLink to="/kanban" primary="Kanban" icon={<Dashboard />} />

            <ListItemLink
                to="/quick-messages"
                primary={i18n.t("mainDrawer.listItems.quickMessages")}
                icon={<OfflineBoltOutlinedIcon />}
            />

            <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<LocalPhoneOutlinedIcon />}
            />

            <ListItemLink
                to="/schedules"
                primary={i18n.t("mainDrawer.listItems.schedules")}
                icon={<EventAvailableOutlinedIcon />}
            />

            <ListItemLink to="/audios" primary={"Áudios"} icon={<Mic />} />

            <ListItemLink
                to="/tags"
                primary={i18n.t("mainDrawer.listItems.tags")}
                icon={<LocalOfferOutlinedIcon />}
            />

            <ListItemLink
                to="/chats"
                primary={i18n.t("mainDrawer.listItems.chats")}
                icon={
                    <Badge
                        color="secondary"
                        variant="dot"
                        invisible={invisible}
                    >
                        <FlipCameraAndroidOutlinedIcon />
                    </Badge>
                }
            />
            {/*
            <ListItemLink
                to="/flow"
                primary={"Fluxos"}
                icon={
                    <Badge
                        color="secondary"
                        variant="dot"
                        invisible={invisible}
                    >
                        <FlipCameraAndroidOutlinedIcon />
                    </Badge>
                }
            /> */}

            {/* eslint-disable-next-line no-restricted-globals */}
            {location.href.includes("synccrm.com.br") && (
                <ListItemLink
                    to="/leads"
                    primary="Leads"
                    icon={<EmailIcon />}
                />
            )}

            <ListItemLink
                to="/helps"
                primary={i18n.t("mainDrawer.listItems.helps")}
                icon={<HelpOutlineOutlinedIcon />}
            />

            <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                    <>
                        <Divider />
                        <ListSubheader inset>
                            {i18n.t("mainDrawer.listItems.administration")}
                        </ListSubheader>
                        {showCampaigns && (
                            <>
                                <ListItem
                                    sx={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setOpenCampaignSubmenu((prev) => !prev)
                                    }}
                                >
                                    <ListItemIcon>
                                        <EventAvailableIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={i18n.t(
                                            "mainDrawer.listItems.campaigns",
                                        )}
                                    />
                                    {openCampaignSubmenu ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </ListItem>
                                <Collapse
                                    style={{ paddingLeft: 15 }}
                                    in={openCampaignSubmenu}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <ListItem
                                            sx={{ cursor: "pointer" }}
                                            onClick={() =>
                                                history.push("/campaigns")
                                            }
                                        >
                                            <ListItemIcon>
                                                <ListIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Listagem" />
                                        </ListItem>
                                        <ListItem
                                            sx={{ cursor: "pointer" }}
                                            onClick={() =>
                                                history.push("/contact-lists")
                                            }
                                        >
                                            <ListItemIcon>
                                                <PeopleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Listas de Contatos" />
                                        </ListItem>
                                        <ListItem
                                            sx={{ cursor: "pointer" }}
                                            onClick={() =>
                                                history.push(
                                                    "/campaigns-config",
                                                )
                                            }
                                        >
                                            <ListItemIcon>
                                                <SettingsOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Configurações" />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {user.super && (
                            <ListItemLink
                                to="/announcements"
                                primary={i18n.t(
                                    "mainDrawer.listItems.annoucements",
                                )}
                                icon={<ErrorOutlineOutlinedIcon />}
                            />
                        )}
                        <ListItemLink
                            to="/prompts"
                            primary={i18n.t("mainDrawer.listItems.prompts")}
                            icon={<BlurCircular />}
                        />

                        <ListItemLink
                            to="/queue-integration"
                            primary={i18n.t(
                                "mainDrawer.listItems.queueIntegration",
                            )}
                            icon={<DeviceHubOutlined />}
                        />

                        <ListItemLink
                            to="/connections"
                            primary={i18n.t("mainDrawer.listItems.connections")}
                            icon={
                                <Badge
                                    badgeContent={connectionWarning ? "!" : 0}
                                    color="error"
                                >
                                    <ContactlessOutlinedIcon />
                                </Badge>
                            }
                        />
                        <ListItemLink
                            to="/queues"
                            primary={i18n.t("mainDrawer.listItems.queues")}
                            icon={<WrapTextOutlinedIcon />}
                        />
                        <ListItemLink
                            to="/users"
                            primary={i18n.t("mainDrawer.listItems.users")}
                            icon={<PeopleAltOutlinedIcon />}
                        />
                        {/* <ListItemLink
                            to="/subsidiaries"
                            primary="Filiais"
                            icon={<StoreIcon />}
                        /> */}
                        <ListItemLink
                            to="/messages-api"
                            primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                            icon={<CodeRoundedIcon />}
                        />
                        <ListItemLink
                            to="/financeiro"
                            primary={i18n.t("mainDrawer.listItems.financeiro")}
                            icon={<MonetizationOnOutlinedIcon />}
                        />
                        <ListItemLink
                            to="/settings"
                            primary={i18n.t("mainDrawer.listItems.settings")}
                            icon={<SettingsOutlinedIcon />}
                        />
                        {/*             <ListItemLink
              to="/subscription"
              primary="Assinatura"
              icon={<PaymentIcon />}
              //className={classes.menuItem}
            /> */}
                    </>
                )}
            />
        </div>
    )
}

export default MainListItems
