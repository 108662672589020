import React, { useState } from "react"
import axios from "axios"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"

import { i18n } from "../../translate/i18n"
import {
    Button,
    CircularProgress,
    Grid2,
    TextField,
    Typography,
} from "@mui/material"
import { Field, Form, Formik } from "formik"
import toastError from "../../errors/toastError"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(2),
        paddingBottom: 100,
    },
    mainHeader: {
        marginTop: theme.spacing(1),
    },
    elementMargin: {
        marginTop: theme.spacing(2),
    },
    formContainer: {
        maxWidth: 500,
    },
    textRight: {
        textAlign: "right",
    },
}))

const MessagesAPI = () => {
    const classes = useStyles()

    const [formMessageTextData] = useState({ token: "", number: "", body: "" })
    const [formMessageMediaData] = useState({
        token: "",
        number: "",
        medias: "",
    })
    const [file, setFile] = useState({})

    const getEndpoint = () => {
        return process.env.REACT_APP_BACKEND_URL + "/api/messages/send"
    }

    const handleSendTextMessage = async (values) => {
        const { number, body } = values
        const data = { number, body }
        var options = {
            method: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/api/messages/send`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${values.token}`,
            },
            data,
        }

        axios
            .request(options)
            .then(function () {
                toast.success("Mensagem enviada com sucesso")
            })
            .catch(function (error) {
                toastError(error)
            })
    }

    const handleSendMediaMessage = async (values) => {
        try {
            const firstFile = file[0]
            const data = new FormData()
            data.append("number", values.number)
            data.append("body", firstFile.name)
            data.append("medias", firstFile)
            var options = {
                method: "POST",
                url: `${process.env.REACT_APP_BACKEND_URL}/api/messages/send`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${values.token}`,
                },
                data,
            }

            axios
                .request(options)
                .then(function () {
                    toast.success("Mensagem enviada com sucesso")
                })
                .catch(function (error) {
                    toastError(error)
                })
        } catch (err) {
            toastError(err)
        }
    }

    const renderFormMessageText = () => {
        return (
            <Formik
                initialValues={formMessageTextData}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    setTimeout(async () => {
                        await handleSendTextMessage(values)
                        actions.setSubmitting(false)
                        actions.resetForm()
                    }, 400)
                }}
                className={classes.elementMargin}
            >
                {({ isSubmitting }) => (
                    <Form className={classes.formContainer}>
                        <Grid2 container spacing={2}>
                            <Grid2 item size={{ xs: 12, md: 6 }}>
                                <Field
                                    as={TextField}
                                    label={i18n.t(
                                        "messagesAPI.textMessage.token",
                                    )}
                                    name="token"
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    className={classes.textField}
                                    required
                                />
                            </Grid2>
                            <Grid2 item size={{ xs: 12, md: 6 }}>
                                <Field
                                    as={TextField}
                                    label={i18n.t(
                                        "messagesAPI.textMessage.number",
                                    )}
                                    name="number"
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    className={classes.textField}
                                    required
                                />
                            </Grid2>
                            <Grid2 item size={{ xs: 12 }}>
                                <Field
                                    as={TextField}
                                    label={i18n.t(
                                        "messagesAPI.textMessage.body",
                                    )}
                                    name="body"
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    className={classes.textField}
                                    required
                                />
                            </Grid2>
                            <Grid2
                                item
                                size={{ xs: 12 }}
                                className={classes.textRight}
                            >
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    ) : (
                                        "Enviar"
                                    )}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Form>
                )}
            </Formik>
        )
    }

    const renderFormMessageMedia = () => {
        return (
            <Formik
                initialValues={formMessageMediaData}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    setTimeout(async () => {
                        console.log(values, file)
                        await handleSendMediaMessage(values)
                        actions.setSubmitting(false)
                        actions.resetForm()
                        document.getElementById("medias").files = null
                        document.getElementById("medias").value = null
                    }, 400)
                }}
                className={classes.elementMargin}
            >
                {({ isSubmitting }) => (
                    <Form className={classes.formContainer}>
                        <Grid2 container spacing={2}>
                            <Grid2 item size={{ xs: 12, md: 6 }}>
                                <Field
                                    as={TextField}
                                    label={i18n.t(
                                        "messagesAPI.mediaMessage.token",
                                    )}
                                    name="token"
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    className={classes.textField}
                                    required
                                />
                            </Grid2>
                            <Grid2 item size={{ xs: 12, md: 6 }}>
                                <Field
                                    as={TextField}
                                    label={i18n.t(
                                        "messagesAPI.mediaMessage.number",
                                    )}
                                    name="number"
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    className={classes.textField}
                                    required
                                />
                            </Grid2>
                            <Grid2 item size={{ xs: 12 }}>
                                <input
                                    type="file"
                                    name="medias"
                                    id="medias"
                                    required
                                    onChange={(e) => setFile(e.target.files)}
                                />
                            </Grid2>
                            <Grid2
                                item
                                size={{ xs: 12 }}
                                className={classes.textRight}
                            >
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    ) : (
                                        "Enviar"
                                    )}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <Paper className={classes.mainPaper} variant="outlined">
            <Typography variant="h5">
                Documentação para envio de mensagens
            </Typography>
            <Typography
                variant="h6"
                color="primary"
                className={classes.elementMargin}
            >
                Métodos de Envio
            </Typography>
            <Typography component="div">
                <ol>
                    <li>Mensagens de Texto</li>
                    <li>Mensagens de Media</li>
                </ol>
            </Typography>
            <Typography
                variant="h6"
                color="primary"
                className={classes.elementMargin}
            >
                Instruções
            </Typography>
            <Typography className={classes.elementMargin} component="div">
                <b>Observações importantes</b>
                <br />
                <ul>
                    <li>
                        Antes de enviar mensagens, é necessário o cadastro do
                        token vinculado à conexão que enviará as mensagens.{" "}
                        <br />
                        Para realizar o cadastro acesse o menu
                        &quot;Conexões&quot;, clique no botão editar da conexão
                        e insira o token no devido campo.
                    </li>
                    <li>
                        O número para envio não deve ter mascara ou caracteres
                        especiais e deve ser composto por:
                        <ul>
                            <li>Código do país</li>
                            <li>DDD</li>
                            <li>Número</li>
                        </ul>
                    </li>
                </ul>
            </Typography>
            <Typography
                variant="h6"
                color="primary"
                className={classes.elementMargin}
            >
                1. Mensagens de Texto
            </Typography>
            <Grid2 container>
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                    <Typography
                        className={classes.elementMargin}
                        component="div"
                    >
                        <p>
                            Seguem abaixo a lista de informações necessárias
                            para envio das mensagens de texto:
                        </p>
                        <b>Endpoint: </b> {getEndpoint()} <br />
                        <b>Método: </b> POST <br />
                        <b>Headers: </b> X_TOKEN (token cadastrado) e
                        Content-Type (application/json) <br />
                        <b>Body: </b>{" "}
                        {'{ "number": "558599999999", "body": "Sua mensagem" }'}
                    </Typography>
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                    <Typography className={classes.elementMargin}>
                        <b>Teste de Envio</b>
                    </Typography>
                    {renderFormMessageText()}
                </Grid2>
            </Grid2>
            <Typography
                variant="h6"
                color="primary"
                className={classes.elementMargin}
            >
                2. Mensagens de Media
            </Typography>
            <Grid2 container>
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                    <Typography
                        className={classes.elementMargin}
                        component="div"
                    >
                        <p>
                            Seguem abaixo a lista de informações necessárias
                            para envio das mensagens de texto:
                        </p>
                        <b>Endpoint: </b> {getEndpoint()} <br />
                        <b>Método: </b> POST <br />
                        <b>Headers: </b> X_TOKEN (token cadastrado) e
                        Content-Type (multipart/form-data) <br />
                        <b>FormData: </b> <br />
                        <ul>
                            <li>
                                <b>number: </b> 558599999999
                            </li>
                            <li>
                                <b>medias: </b> arquivo
                            </li>
                        </ul>
                    </Typography>
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                    <Typography className={classes.elementMargin}>
                        <b>Teste de Envio</b>
                    </Typography>
                    {renderFormMessageMedia()}
                </Grid2>
            </Grid2>

            <Typography variant="h5">
                Documentação para gerenciamento de LEADS
            </Typography>
            <Typography
                variant="h6"
                color="primary"
                className={classes.elementMargin}
            >
                1. Criação de Lead único
            </Typography>
            <Grid2 container>
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                    <Typography
                        className={classes.elementMargin}
                        component="div"
                    >
                        <b>Endpoint: </b>{" "}
                        {`${process.env.REACT_APP_BACKEND_URL}/api/leads/create`}{" "}
                        <br />
                        <b>Método: </b> POST <br />
                        <b>Headers: </b> X_TOKEN (token cadastrado) e
                        Content-Type (application/json) <br />
                        <b>Body: </b>{" "}
                        <code>
                            {
                                '{"name": "NOME DO LEAD", "number": "CONTATOS DO LEAD", "email": "E-MAIL DO LEAD", "referenceId": "CÓDIGO", "pipeCode": "PIPELINE", "cardCode": "REF INTERESSE", "origin": "CANAL DE ORIGEM"}'
                            }
                        </code>
                    </Typography>
                </Grid2>
            </Grid2>
            <Typography
                variant="h6"
                color="primary"
                className={classes.elementMargin}
            >
                2. Imporat lista de LEADS
            </Typography>
            <Grid2 container>
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                    <Typography
                        className={classes.elementMargin}
                        component="div"
                    >
                        <b>Endpoint: </b>{" "}
                        {`${process.env.REACT_APP_BACKEND_URL}/api/leads/import`}{" "}
                        <br />
                        <b>Método: </b> POST <br />
                        <b>Headers: </b> X_TOKEN (token cadastrado) e
                        Content-Type (application/json) <br />
                        <b>Body: </b>{" "}
                        <code>
                            {
                                '[{"NOME DO LEAD": "Nome Lead 1", "CONTATOS DO LEAD": "551199999999", "E-MAIL DO LEAD": "example@mail.com", "CÓDIGO": "020081", "PIPELINE": "Ops Locação", "REF INTERESSE": "84698", "origin": "Atendimento presencial"}, ...]'
                            }
                        </code>
                    </Typography>
                </Grid2>
            </Grid2>
        </Paper>
    )
}

export default MessagesAPI
