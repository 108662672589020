import React, { useState, useEffect, useRef, useContext } from "react"

import { useHistory, useParams } from "react-router-dom"
import { parseISO, format, isSameDay } from "date-fns"
import clsx from "clsx"

import { makeStyles } from "@mui/styles"
import { green, grey, red, blue } from "@mui/material/colors"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"

import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Divider from "@mui/material/Divider"

import Box from "@mui/material/Box"

import api from "../../services/api"

import MarkdownWrapper from "../MarkdownWrapper"
import { Chip, Grid2, IconButton, Tooltip } from "@mui/material"
import { AuthContext } from "../../context/Auth/AuthContext"
import { TicketsContext } from "../../context/Tickets/TicketsContext"
import toastError from "../../errors/toastError"
import { v4 as uuidv4 } from "uuid"

import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import AndroidIcon from "@mui/icons-material/Android"
import VisibilityIcon from "@mui/icons-material/Visibility"
import TicketMessagesDialog from "../TicketMessagesDialog"
import DoneIcon from "@mui/icons-material/Done"
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined"
import { FacebookOutlined, Instagram } from "@mui/icons-material"

const useStyles = makeStyles(() => ({
    ticket: {
        position: "relative",
        paddingHorizontal: 10,
        // paddingVertical: 0,
        justifyContent: "space-between !important",
        paddingBottom: "10 !important",
    },

    pendingTicket: {
        cursor: "unset",
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },

    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    contactNameWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
    },
    contactNameChannelWrapper: {
        display: "flex",
        // alignItems: "center",
        gap: 2,
    },

    lastMessageTime: {
        textAlign: "right",
        fontSize: 12,
    },

    closedBadge: {
        alignSelf: "center",
        justifySelf: "flex-end",
        marginRight: 32,
        marginLeft: "auto",
    },

    contactLastMessage: {
        // paddingRight: "50%",
    },

    newMessagesCount: {
        alignSelf: "center",
        marginRight: 0,
        marginLeft: "auto",
        top: -10,
        right: 10,
    },

    badgeStyle: {
        color: "white",
        backgroundColor: green[500],
        right: 0,
        top: 10,
    },

    acceptButton: {
        position: "absolute",
        right: "108px",
    },

    ticketQueueColor: {
        flex: "none",
        width: "8px",
        height: "100%",
        position: "absolute",
        top: "0%",
        left: "0%",
    },

    ticketInfo: {
        position: "relative",
        top: 0,
    },

    ticketInfo1: {
        display: "flex",
        flexWrap: "wrap",
    },
    Radiusdot: {
        "& .MuiBadge-badge": {
            borderRadius: 2,
            position: "inherit",
            height: 16,
            margin: 2,
            padding: 3,
            fontSize: 10,
        },
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            transform: "scale(1) translate(0%, -40%)",
        },
    },
}))

const TicketListItemCustom = ({ ticket }) => {
    const classes = useStyles()
    const history = useHistory()
    const [ticketUser, setTicketUser] = useState(null)
    const [whatsAppName, setWhatsAppName] = useState(null)

    const [openTicketMessageDialog, setOpenTicketMessageDialog] =
        useState(false)
    const { ticketId } = useParams()
    const isMounted = useRef(true)
    const { setCurrentTicket } = useContext(TicketsContext)
    const { user } = useContext(AuthContext)
    const { profile } = user

    useEffect(() => {}, [])

    useEffect(() => {
        if (ticket.userId && ticket.user) {
            setTicketUser(ticket.user.name)
        }

        if (ticket.whatsappId && ticket.whatsapp) {
            setWhatsAppName(ticket.whatsapp.name)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    const handleCloseTicket = async (id) => {
        try {
            await api.put(`/tickets/${id}`, {
                status: "closed",
                justClose: true,
                userId: user?.id,
            })
        } catch (err) {
            toastError(err)
        }

        history.push(`/tickets/`)
    }

    const handleAcepptTicket = async (id) => {
        try {
            await api.put(`/tickets/${id}`, {
                status: "open",
                userId: user?.id,
            })
        } catch (err) {
            toastError(err)
        }

        history.push(`/tickets/${ticket.uuid}`)
    }

    const handleSelectTicket = (ticket) => {
        const code = uuidv4()
        const { id, uuid } = ticket
        setCurrentTicket({ id, uuid, code })
    }

    const renderTicketInfo = () => {
        if (ticketUser) {
            return (
                <>
                    <Chip
                        size="small"
                        label={`${ticketUser}`}
                        sx={{
                            backgroundColor: "#3498db",
                            color: "white",
                            marginRight: "2px",
                        }}
                    />

                    {ticket.whatsappId && (
                        <Chip
                            size="small"
                            label={`${whatsAppName}`}
                            sx={{
                                backgroundColor: "#7d79f2",
                                color: "white",
                                marginRight: "2px",
                            }}
                        />
                    )}

                    {ticket.queue?.name !== null && (
                        <Chip
                            size="small"
                            label={ticket.queue?.name || "Sem fila"}
                            sx={{
                                backgroundColor:
                                    ticket.queue?.color || "#7C7C7C",
                                color: "white",
                                marginRight: "2px",
                            }}
                        />
                    )}

                    {ticket.chatbot && (
                        <Tooltip title="Chatbot">
                            <AndroidIcon
                                fontSize="small"
                                style={{ color: grey[700], marginRight: 5 }}
                            />
                        </Tooltip>
                    )}
                </>
            )
        } else {
            return (
                <>
                    {ticket.whatsappId && (
                        <Chip
                            size="small"
                            label={`${whatsAppName}`}
                            sx={{
                                backgroundColor: "#7d79f2",
                                color: "white",
                                marginRight: "2px",
                            }}
                        />
                    )}

                    {ticket.queue?.name !== null && (
                        <Chip
                            size="small"
                            label={ticket.queue?.name || "Sem fila"}
                            sx={{
                                backgroundColor: "#7C7C7C",
                                color: "white",
                                marginRight: "2px",
                            }}
                        />
                    )}

                    {ticket.chatbot && (
                        <Tooltip title="Chatbot">
                            <AndroidIcon
                                fontSize="small"
                                style={{ color: grey[700], marginRight: 5 }}
                            />
                        </Tooltip>
                    )}
                </>
            )
        }
    }

    return (
        <React.Fragment key={ticket.id}>
            <TicketMessagesDialog
                open={openTicketMessageDialog}
                handleClose={() => setOpenTicketMessageDialog(false)}
                ticketId={ticket.id}
            ></TicketMessagesDialog>
            <ListItem
                dense
                button
                onClick={() => {
                    if (ticket.status === "pending") return
                    handleSelectTicket(ticket)
                }}
                selected={ticketId && +ticketId === ticket.id}
                className={clsx(classes.ticket, {
                    [classes.pendingTicket]: ticket.status === "pending",
                })}
            >
                <Grid2 flexBasis="15%">
                    <Tooltip
                        arrow
                        placement="right"
                        title={ticket.queue?.name || "Sem fila"}
                    >
                        <span
                            style={{
                                backgroundColor:
                                    ticket.queue?.color || "#7C7C7C",
                            }}
                            className={classes.ticketQueueColor}
                        ></span>
                    </Tooltip>
                    <ListItemAvatar>
                        <Avatar src={ticket?.contact?.profilePicUrl} />
                    </ListItemAvatar>
                </Grid2>
                <Grid2 display="flex" flexBasis="55%">
                    <Grid2 display="flex" flexDirection={"column"}>
                        <ListItemText
                            disableTypography
                            primary={
                                <span
                                    className={
                                        classes.contactNameChannelWrapper
                                    }
                                >
                                    {ticket.channel === "whatsapp" && (
                                        <Tooltip
                                            title={`Atribuido à ${ticketUser}`}
                                        >
                                            <WhatsAppIcon
                                                fontSize="inherit"
                                                style={{ color: grey[700] }}
                                            />
                                        </Tooltip>
                                    )}
                                    {ticket.channel === "facebook" && (
                                        <Tooltip
                                            title={`Atribuido à ${ticketUser}`}
                                        >
                                            <FacebookOutlined
                                                fontSize="inherit"
                                                style={{ color: grey[700] }}
                                            />
                                        </Tooltip>
                                    )}
                                    {ticket.channel === "instagram" && (
                                        <Tooltip
                                            title={`Atribuido à ${ticketUser}`}
                                        >
                                            <Instagram
                                                fontSize="inherit"
                                                style={{ color: grey[700] }}
                                            />
                                        </Tooltip>
                                    )}
                                    <Typography
                                        noWrap
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                    >
                                        {ticket.contact.name}
                                    </Typography>
                                </span>
                            }
                            secondary={
                                <span className={classes.contactNameWrapper}>
                                    <Typography
                                        className={classes.contactLastMessage}
                                        noWrap
                                        component="span"
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {" "}
                                        {ticket.lastMessage.includes(
                                            "data:image/png;base64",
                                        ) ? (
                                            <MarkdownWrapper>
                                                {" "}
                                                Localização
                                            </MarkdownWrapper>
                                        ) : (
                                            <MarkdownWrapper>
                                                {ticket.lastMessage}
                                            </MarkdownWrapper>
                                        )}
                                        {/* {ticket.lastMessage === "" ? <br /> : <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>} */}
                                    </Typography>
                                </span>
                            }
                        />

                        <Box className={classes.ticketInfo1}>
                            {renderTicketInfo()}
                            {ticket.status === "closed" && (
                                <Chip
                                    size="small"
                                    label={`FECHADO`}
                                    sx={{
                                        backgroundColor:
                                            ticket.queue?.color || "#ff0000",
                                        color: "white",
                                        marginRight: 5,
                                    }}
                                />
                            )}
                        </Box>
                    </Grid2>
                </Grid2>
                <Grid2 display="flex" flexDirection="column" flexBasis="30%">
                    {ticket.lastMessage && (
                        <>
                            <Typography
                                className={classes.lastMessageTime}
                                component="span"
                                variant="body2"
                                color="textSecondary"
                            >
                                {isSameDay(
                                    parseISO(ticket.updatedAt),
                                    new Date(),
                                ) ? (
                                    <>
                                        {format(
                                            parseISO(ticket.updatedAt),
                                            "HH:mm",
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {format(
                                            parseISO(ticket.updatedAt),
                                            "dd/MM/yyyy",
                                        )}
                                    </>
                                )}
                            </Typography>

                            {!!ticket.unreadMessages && (
                                <Chip
                                    className={classes.newMessagesCount}
                                    label={
                                        ticket.unreadMessages
                                            ? ticket.unreadMessages
                                            : null
                                    }
                                    classes={{
                                        badge: classes.badgeStyle,
                                    }}
                                />
                            )}
                        </>
                    )}

                    <Grid2
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                    >
                        {ticket.status === "open" && (
                            <Tooltip title="Fechar Conversa">
                                <IconButton
                                    onClick={() => handleCloseTicket(ticket.id)}
                                >
                                    <ClearOutlinedIcon
                                        fontSize="small"
                                        sx={{
                                            color: "#fff",
                                            backgroundColor: red[700],
                                            borderRadius: 50,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        {profile === "admin" && (
                            <Tooltip title="Espiar Conversa">
                                <IconButton
                                    onClick={() =>
                                        setOpenTicketMessageDialog(true)
                                    }
                                >
                                    <VisibilityIcon
                                        fontSize="small"
                                        style={{
                                            padding: 2,
                                            height: 23,
                                            width: 23,
                                            fontSize: 12,
                                            color: "#fff",
                                            cursor: "pointer",
                                            backgroundColor: blue[700],
                                            borderRadius: 50,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        {ticket.status === "pending" && (
                            <Tooltip title="Fechar Conversa">
                                <IconButton
                                    onClick={() => handleCloseTicket(ticket.id)}
                                >
                                    <ClearOutlinedIcon
                                        fontSize="small"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: red[700],
                                            cursor: "pointer",
                                            padding: 2,
                                            right: 48,
                                            height: 23,
                                            width: 23,
                                            fontSize: 12,
                                            borderRadius: 50,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}

                        {ticket.status === "pending" && (
                            <Tooltip title="Aceitar Conversa">
                                <IconButton
                                    onClick={() =>
                                        handleAcepptTicket(ticket.id)
                                    }
                                >
                                    <DoneIcon
                                        fontSize="small"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: green[700],
                                            cursor: "pointer",
                                            padding: 2,
                                            height: 23,
                                            width: 23,
                                            fontSize: 12,
                                            borderRadius: 50,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid2>
                </Grid2>
            </ListItem>
            <Divider variant="inset" component="li" />
        </React.Fragment>
    )
}

export default TicketListItemCustom
