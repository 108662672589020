import api from "../../services/api"

const useSubscribe = () => {
    const getSubscribeByCompany = async (companyId) => {
        return await api.request({
            url: `/admin/subscription/${companyId}`,
            method: "GET",
        })
    }

    return {
        getSubscribeByCompany,
    }
}

export default useSubscribe
