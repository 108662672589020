import React, { useState, useEffect } from "react"
import {
    Paper,
    Grid2,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    Select,
} from "@mui/material"

import { makeStyles } from "@mui/styles"

import { Formik, Form, Field } from "formik"
import ButtonWithSpinner from "../ButtonWithSpinner"
import ConfirmationModal from "../ConfirmationModal"

import { Edit as EditIcon } from "@mui/icons-material"

import { toast } from "react-toastify"
import useCompanies from "../../hooks/useCompanies"

import ModalUsers from "../ModalUsers"
import ModalSubscription from "../ModalSubscription"
import api from "../../services/api"
import { head, isArray, has } from "lodash"
import { useDate } from "../../hooks/useDate"
import InputMask from "react-input-mask"

import { formatWhatsNumber } from "../../utils/mask"

import moment from "moment"
import useAuth from "../../hooks/useAuth.js"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    mainPaper: {
        width: "100%",
        flex: 1,
        padding: theme.spacing(2),
    },
    fullWidth: {
        width: "100%",
    },
    tableContainer: {
        width: "100%",
        overflowX: "scroll",
        ...theme.scrollbarStyles,
    },
    textfield: {
        width: "100%",
    },
    textRight: {
        textAlign: "right",
    },
    row: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    control: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    buttonContainer: {
        textAlign: "right",
        padding: theme.spacing(1),
    },
}))

export function CompanyForm(props) {
    const { onSubmit, onDelete, onCancel, initialValue, loading, isSuper } =
        props
    const classes = useStyles()
    const [modalUser, setModalUser] = useState(false)
    const [modalSubscription, setModalSubscription] = useState(false)
    const [firstUser, setFirstUser] = useState({})
    const [tipoPessoa, setTipoPessoa] = useState("pf")

    const [record, setRecord] = useState({
        name: "",
        email: "",
        phone: "",
        status: true,
        campaignsEnabled: false,
        dueDate: "",
        document: "",
        recurrence: "",
        ...initialValue,
    })

    useEffect(() => {
        if (record.document.length > 11) {
            setTipoPessoa("pj")
        } else {
            setTipoPessoa("pf")
        }
    }, [record.document])

    useEffect(() => {
        setRecord((prev) => {
            if (moment(initialValue).isValid()) {
                initialValue.dueDate = moment(initialValue.dueDate).format(
                    "YYYY-MM-DD",
                )
            }
            return {
                ...prev,
                ...initialValue,
            }
        })
    }, [initialValue])

    const handleSubmit = async (data) => {
        if (data.dueDate === "" || moment(data.dueDate).isValid() === false) {
            data.dueDate = null
        }
        onSubmit(data)
        setRecord({ ...initialValue, dueDate: "" })
    }

    const handleOpenModalUsers = async () => {
        try {
            const { data } = await api.get("/users/list", {
                params: {
                    companyId: initialValue.id,
                },
            })
            if (isArray(data) && data.length) {
                setFirstUser(head(data))
            }
            setModalUser(true)
        } catch (e) {
            toast.error(e)
        }
    }

    const handleCloseModalUsers = () => {
        setFirstUser({})
        setModalUser(false)
    }

    const CustomInputDoc = (props) => {
        return (
            <InputMask
                prefix="teste"
                mask={
                    tipoPessoa === "pf"
                        ? "999.999.999-99"
                        : "99.999.999/9999-99"
                }
                {...props}
            >
                {(inputProps) => <TextField {...inputProps} size="xs" />}
            </InputMask>
        )
    }

    return (
        <>
            <ModalSubscription
                open={modalSubscription}
                onClose={() => setModalSubscription(false)}
                companyId={initialValue.id}
            />
            <ModalUsers
                userId={firstUser.id}
                companyId={initialValue.id}
                open={modalUser}
                onClose={handleCloseModalUsers}
            />
            <Formik
                enableReinitialize
                className={classes.fullWidth}
                initialValues={record}
                onSubmit={(values, { resetForm }) =>
                    setTimeout(() => {
                        handleSubmit(values)
                        resetForm()
                    }, 500)
                }
            >
                {() => (
                    <Form className={classes.fullWidth}>
                        <Grid2 spacing={2} justifyContent="flex-end" container>
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    md: 4,
                                }}
                                item
                            >
                                <Field
                                    as={TextField}
                                    label="Nome"
                                    name="name"
                                    variant="outlined"
                                    className={classes.fullWidth}
                                    margin="dense"
                                />
                            </Grid2>
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    md: 2,
                                }}
                                item
                            >
                                <Field
                                    as={TextField}
                                    label="E-mail"
                                    name="email"
                                    variant="outlined"
                                    className={classes.fullWidth}
                                    margin="dense"
                                    required
                                />
                            </Grid2>
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    md: 2,
                                }}
                                item
                            >
                                <Field
                                    as={CustomInputDoc}
                                    variant="outlined"
                                    fullWidth
                                    id="document"
                                    label={tipoPessoa === "pf" ? "CPF" : "CNPJ"}
                                    name="document"
                                    autoComplete="document"
                                    required
                                />
                            </Grid2>
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    md: 2,
                                }}
                                item
                            >
                                <Field
                                    as={TextField}
                                    label="Telefone"
                                    name="phone"
                                    variant="outlined"
                                    className={classes.fullWidth}
                                    margin="dense"
                                />
                            </Grid2>

                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    md: 2,
                                }}
                                item
                            >
                                <FormControl
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                >
                                    <InputLabel htmlFor="status-selection">
                                        Status
                                    </InputLabel>
                                    <Field
                                        as={Select}
                                        id="status-selection"
                                        label="Status"
                                        labelId="status-selection-label"
                                        name="status"
                                        margin="dense"
                                    >
                                        <MenuItem value={true}>Sim</MenuItem>
                                        <MenuItem value={false}>Não</MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid2>
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    md: 2,
                                }}
                                item
                            >
                                <FormControl
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                >
                                    <InputLabel htmlFor="status-selection">
                                        Pessoa
                                    </InputLabel>
                                    <Field
                                        as={Select}
                                        id="persontype-selection"
                                        label="Pessoa"
                                        margin="dense"
                                        value={tipoPessoa}
                                        onChange={(e) =>
                                            setTipoPessoa(e.target.value)
                                        }
                                    >
                                        <MenuItem value={"pf"}>
                                            Pessoa física
                                        </MenuItem>
                                        <MenuItem value={"pj"}>
                                            Pessoa jurídica
                                        </MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid2>
                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    md: 2,
                                }}
                                item
                            >
                                <FormControl
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                >
                                    <InputLabel htmlFor="status-selection">
                                        Campanhas
                                    </InputLabel>
                                    <Field
                                        as={Select}
                                        id="campaigns-selection"
                                        label="Campanhas"
                                        labelId="campaigns-selection-label"
                                        name="campaignsEnabled"
                                        margin="dense"
                                    >
                                        <MenuItem value={true}>
                                            Habilitadas
                                        </MenuItem>
                                        <MenuItem value={false}>
                                            Desabilitadas
                                        </MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid2>
                            <Grid2
                                size={{
                                    xs: 12,
                                }}
                                item
                            >
                                <Grid2
                                    justifyContent="flex-end"
                                    spacing={1}
                                    container
                                >
                                    <Grid2
                                        size={{
                                            xs: 4,
                                            md: 1,
                                        }}
                                        item
                                    >
                                        <ButtonWithSpinner
                                            className={classes.fullWidth}
                                            style={{ marginTop: 7 }}
                                            loading={loading}
                                            onClick={() => onCancel()}
                                            variant="contained"
                                        >
                                            Limpar
                                        </ButtonWithSpinner>
                                    </Grid2>
                                    {record.id !== undefined ? (
                                        <>
                                            {isSuper && (
                                                <Grid2
                                                    size={{
                                                        xs: 6,
                                                        md: 1,
                                                    }}
                                                    item
                                                >
                                                    <ButtonWithSpinner
                                                        style={{ marginTop: 7 }}
                                                        className={
                                                            classes.fullWidth
                                                        }
                                                        loading={loading}
                                                        onClick={() =>
                                                            onDelete(record)
                                                        }
                                                        variant="contained"
                                                        color="secondary"
                                                    >
                                                        Excluir
                                                    </ButtonWithSpinner>
                                                </Grid2>
                                            )}
                                            {isSuper && (
                                                <Grid2
                                                    size={{
                                                        xs: 6,
                                                        md: 2,
                                                    }}
                                                    item
                                                >
                                                    <ButtonWithSpinner
                                                        style={{ marginTop: 7 }}
                                                        className={
                                                            classes.fullWidth
                                                        }
                                                        loading={loading}
                                                        onClick={() =>
                                                            setModalSubscription(
                                                                true,
                                                            )
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Assinatura
                                                    </ButtonWithSpinner>
                                                </Grid2>
                                            )}
                                            <Grid2
                                                size={{
                                                    xs: 6,
                                                    md: 1,
                                                }}
                                                item
                                            >
                                                <ButtonWithSpinner
                                                    style={{ marginTop: 7 }}
                                                    className={
                                                        classes.fullWidth
                                                    }
                                                    loading={loading}
                                                    onClick={() =>
                                                        handleOpenModalUsers()
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Usuário
                                                </ButtonWithSpinner>
                                            </Grid2>
                                        </>
                                    ) : null}
                                    <Grid2
                                        size={{
                                            xs: 6,
                                            md: 1,
                                        }}
                                        item
                                    >
                                        <ButtonWithSpinner
                                            className={classes.fullWidth}
                                            style={{ marginTop: 7 }}
                                            loading={loading}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Salvar
                                        </ButtonWithSpinner>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export function CompaniesManagerGrid(props) {
    const { records, onSelect } = props
    const classes = useStyles()
    const { dateToClient } = useDate()

    const renderStatus = (row) => {
        return row.status === false ? "Não" : "Sim"
    }

    const renderCampaignsStatus = (row) => {
        if (
            has(row, "settings") &&
            isArray(row.settings) &&
            row.settings.length > 0
        ) {
            const setting = row.settings.find(
                (s) => s.key === "campaignsEnabled",
            )
            if (setting) {
                return setting.value === "true"
                    ? "Habilitadas"
                    : "Desabilitadas"
            }
        }
        return "Desabilitadas"
    }

    const rowStyle = (record) => {
        if (moment(record.dueDate).isValid()) {
            const now = moment()
            const dueDate = moment(record.dueDate)
            const diff = dueDate.diff(now, "days")
            if (diff === 5) {
                return { backgroundColor: "#fffead" }
            }
            if (diff >= -3 && diff <= 4) {
                return { backgroundColor: "#f7cc8f" }
            }
            if (diff === -4) {
                return { backgroundColor: "#fa8c8c" }
            }
        }
        return {}
    }

    return (
        <Paper className={classes.tableContainer}>
            <Table
                className={classes.fullWidth}
                size="small"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="center" style={{ width: "1%" }}>
                            #
                        </TableCell>
                        <TableCell align="left">Nome</TableCell>
                        <TableCell align="left">E-mail</TableCell>
                        <TableCell align="left">Telefone</TableCell>
                        <TableCell align="left">Campanhas</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Criada Em</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((row, key) => (
                        <TableRow style={rowStyle(row)} key={key}>
                            <TableCell align="center" style={{ width: "1%" }}>
                                <IconButton
                                    onClick={() => onSelect(row)}
                                    aria-label="delete"
                                >
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="left">
                                {row.name || "-"}
                            </TableCell>
                            <TableCell align="left">
                                {row.email || "-"}
                            </TableCell>
                            <TableCell align="left">
                                {formatWhatsNumber(row.phone) || "-"}
                            </TableCell>
                            <TableCell align="left">
                                {renderCampaignsStatus(row)}
                            </TableCell>
                            <TableCell align="left">
                                {renderStatus(row)}
                            </TableCell>
                            <TableCell align="left">
                                {dateToClient(row.createdAt)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
}

export default function CompaniesManager() {
    const classes = useStyles()
    const { list, save, update, remove } = useCompanies()

    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [records, setRecords] = useState([])
    const [currentUser, setCurrentUser] = useState({})
    const [isSuper, setIsSuper] = useState(false)
    const companyId = localStorage.getItem("companyId")
    const { getCurrentUserInfo } = useAuth()
    const [record, setRecord] = useState({
        name: "",
        email: "",
        phone: "",
        status: true,
        document: "",
        campaignsEnabled: false,
        dueDate: "",
        recurrence: "",
    })

    useEffect(() => {
        async function getUser() {
            const user = await getCurrentUserInfo()
            setCurrentUser(user)
        }
        getUser()
    }, [])

    useEffect(() => {
        setIsSuper(currentUser.super)
    }, [currentUser.super])

    useEffect(() => {
        loadPlans()
    }, [isSuper])

    const loadPlans = async () => {
        setLoading(true)
        try {
            const companyList = await list()
            const lista = isSuper
                ? companyList
                : companyList.filter(
                      (company) => Number(company.id) === Number(companyId),
                  )
            setRecords(lista)
        } catch (e) {
            toast.error("Não foi possível carregar a lista de registros")
        }
        setLoading(false)
    }

    const handleSubmit = async (data) => {
        setLoading(true)
        try {
            Object.assign(data, { document: data.document.replace(/\D/g, "") })
            if (data.id !== undefined) {
                await update(data)
            } else {
                if (isSuper) {
                    await save(data)
                }
            }
            await loadPlans()
            handleCancel()
            toast.success("Operação realizada com sucesso!")
        } catch (e) {
            toast.error(
                "Não foi possível realizar a operação. Verifique se já existe uma empresa com o mesmo nome ou se os campos foram preenchidos corretamente",
            )
        }
        setLoading(false)
    }

    const handleDelete = async () => {
        setLoading(true)
        try {
            await remove(record.id)
            await loadPlans()
            handleCancel()
            toast.success("Operação realizada com sucesso!")
        } catch (e) {
            toast.error("Não foi possível realizar a operação")
        }
        setLoading(false)
    }

    const handleOpenDeleteDialog = () => {
        setShowConfirmDialog(true)
    }

    const handleCancel = () => {
        setRecord((prev) => ({
            ...prev,
            name: "",
            email: "",
            phone: "",
            document: "",
            status: true,
            campaignsEnabled: false,
            dueDate: "",
            recurrence: "",
        }))
    }

    const handleSelect = (data) => {
        let campaignsEnabled = false

        const setting = data.settings.find(
            (s) => s.key.indexOf("campaignsEnabled") > -1,
        )
        if (setting) {
            campaignsEnabled =
                setting.value === "true" || setting.value === "enabled"
        }

        setRecord((prev) => ({
            ...prev,
            id: data.id,
            name: data.name || "",
            phone: data.phone || "",
            email: data.email || "",
            document: data.document || "",
            status: data.status === false ? false : true,
            campaignsEnabled,
            dueDate: data.dueDate || "",
            recurrence: data.recurrence || "",
        }))
    }

    return (
        <Paper className={classes.mainPaper} elevation={0}>
            <Grid2 spacing={2} container>
                <Grid2
                    size={{
                        xs: 12,
                    }}
                    item
                >
                    <CompanyForm
                        initialValue={record}
                        onDelete={handleOpenDeleteDialog}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        loading={loading}
                        isSuper={isSuper}
                    />
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                    }}
                    item
                >
                    <CompaniesManagerGrid
                        records={records}
                        onSelect={handleSelect}
                    />
                </Grid2>
            </Grid2>
            <ConfirmationModal
                title="Exclusão de Registro"
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={() => handleDelete()}
            >
                Deseja realmente excluir esse registro?
            </ConfirmationModal>
        </Paper>
    )
}
